import axios from 'axios'
import constants from '../const'
import { useStore } from 'vuex';

const store = useStore();
const paymentHttp = axios.create({
  baseURL: process.env.VUE_APP_APISERVER
});
paymentHttp.defaults.headers.common["Authorization"] = 'Basic ' + btoa(process.env.VUE_APP_PM_PUBLIC_KEY);
paymentHttp.defaults.headers.common["X-Papi-Key"] = process.env.VUE_APP_PAPI_KEY;
paymentHttp.defaults.headers.common["X-AppPlatform"] = process.env.VUE_APP_BUILD_TARGET;
if (process.env.VUE_APP_BUILD_TARGET == 'mobile') {
  paymentHttp.defaults.headers.common['X-AppVer'] = btoa(process.env.VUE_APP_VERSION);
}
paymentHttp.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status == 400) {
      if (error.response.data.status == 'update_required') {
        console.error('You are using an outdated version of the app. The system requires you to update to use its services.');
        localStorage.setItem("required_version", error.response.data.data.required_version);
        localStorage.setItem("app_store", error.response.data.data.app_store);
        localStorage.setItem("play_store", error.response.data.data.play_store);
        store.commit('system/requireUpdate');
      }
    }
  }
);

const payment = {
  state: {
    view: 1,
    data: {
      booking: null,
      invoice: null
    },
    loading: {
      show: false,
      title: '',
      subtitle: ''
    },
    error: {
      show: false,
      message: ''
    }
  },
  mutations: {
    setView(state, value = 1) {
      state.view = value;
    },
    setData(state, data) {
      state.data = data;
    },
    setPaymentIntentId(state, paymentIntentId) {
      if (state.data.invoice != null) {
        state.data.invoice.payment_intent_id = paymentIntentId;
      }
    },
    setLoading(state, payload) {
      state.loading.show = payload.show;
      state.loading.title = payload.title;
      state.loading.subtitle = payload.subtitle;
    },
    setError(state, payload) {
      state.error.show = payload.show;
      state.error.message = payload.message;
    }
  },
  actions: {
    submitCard(_, card) {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: 'https://api.paymongo.com/v1/payment_methods',
          data: {
            data: {
              attributes: {
                details: {
                  card_number: card.number,
                  exp_month: Number(card.expMonth),
                  exp_year: Number(card.expYear),
                  cvc: String(card.securityCode)
                },
                type: constants.paymentMethods.card
              }
            }
          }
        }).then((response) => {
          return resolve({
            status: 'card_submitted',
            paymentMethodId: response.data['data']['id']
          });
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    submitGcash() {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: 'https://api.paymongo.com/v1/payment_methods',
          data: {
            data: {
              attributes: {
                type: constants.paymentMethods.gcash
              }
            }
          }
        }).then((response) => {
          return resolve({
            status: 'success',
            paymentMethodId: response.data['data']['id']
          });
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    get({commit}, payload) {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'GET',
          url: '/api/v1/payments?booking_id=' + payload.booking_id
        }).then((response) => {
          commit('setData', response.data.data);
          return resolve(response.data.data);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    initialize({commit}, invoiceNumber) {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          data: {
            invoice_number: invoiceNumber
          },
          url:  '/api/v1/payments/initialize',
        }).then((response) => {
          commit('setPaymentIntentId', response.data.data.payment_intent_id);

          return resolve(response.data.data.payment_intent_id);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    },
    pay({commit}, payload) {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: '/api/v1/payments',
          data: {
            invoice_id: payload.invoice_id,
            payment_intent_id: payload.paymentIntentId,
            payment_method_id: payload.paymentMethodId
          }
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    },
    continue({commit}, payload) {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: '/api/v1/payments/continue',
          data: {
            invoice_id: payload.invoice_id,
            payment_intent_id: payload.payment_intent_id
          }
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        }).finally(() => {
          commit('setLoading', {
            show: false,
            title: '',
            subtitle: ''
          })
        })
      });
    }
  },
  namespaced: true,
};

export default payment;