<template>
  <section class="flex justify-center q-pa-sm">
    <q-card flat bordered style="min-width: 350px; width: 550px; max-width: 600px" class="q-my-lg">
        <q-card-section>
            <div class="text-h6 text-bold text-accent">Email Verification</div>
            <p>If you need to re-verify your email address that was not completed during registration, you can enter you email address here.</p>
            <q-input
                v-model="email.value"
                filled
                type="text"
                autocomplete="off"
                placeholder="Enter registered email address"
                class="q-py-xs"
                :error="email.error != null"
                :error-message="email.error"
            />
        </q-card-section>
        <q-card-section>
            <div class="row">
                <div class="col-12 col-md-6 q-pa-sm">
                    <q-btn class="btn-primary" color="secondary" to="/">Cancel</q-btn>
                </div>
                <div class="col-12 col-md-6 q-pa-sm">
                    <q-btn class="btn-primary" @click="submit">Submit</q-btn>
                </div>
            </div>
        </q-card-section>
    </q-card>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router'

const router = useRouter();

const email = ref({
    value: null,
    error: null
});

function submit() {
    email.value.error = null;

    if (email.value.value == '') {
        email.value.error = 'Please enter your email address that was registered.'
        return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.value.value)) {
        email.value.error = 'You entered an invalid email address.'
        return;
    }

    router.push('/registration?email=' + email.value.value);
}
</script>