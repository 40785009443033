// Contains the script to execute whenever the system ends the startup boot process
// of performing a GET init from the server.
import Loading from 'quasar/src/plugins/Loading.js';; // Import Quasar's loading plugin
import store from "@/store"; // Import the Vuex store directly

export function onBootEnd() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {
      Loading.show({message: 'Validating..'});
      store.dispatch("affiliate/transmit", code).then((response) => {
        const affiliate = response.affiliate;
        if (affiliate.is_available == true && affiliate.discount !== null) {
          store.commit('toggleAffiliateForm', true);
        }
      }).finally(() => {
        Loading.hide();
      })
    }

    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');

    if (token && id) {
        var payload = {
            mode: 'auto-sign-in',
            token: token,
            id: id
        };

        if (code) {
            payload.code = store.state.affiliate.code;
        }
        Loading.show({message: 'Signing In..'});
        store.dispatch('user/signIn', payload).then((response) => {
          store.commit('affiliate/setAffiliate', response.affiliate);
          store.commit('setisLoggedIn', true);
        }).finally(() => {
          Loading.hide();
        });
    }
}
