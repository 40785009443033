<template>
  <div class="container profileDashboard">
    <section>
      <h5 class="text-accent"><q-icon name="delete"/> Account Deletion</h5>
      <br>
      <b class="text-accent">For Client Accounts</b><br><br>
      <p>You can remove your account from our system including all the details you provided and the files that you uploaded. Here are all the details and files that we will remove from the system:</p>
      <ol>
        <li>Any details about you including (but not limited to) your name, birth date, mobile number, email address, address and driver's license details.</li>
        <li>Any uploaded files such as photo of you, driver's license including selfie, back of your license and front of your license.</li>
      </ol>
      <p class="text-bold">Please be advised that you cannot recover your account when the deletion has taken place. However, you can always create an account just in case you need to sign up again.</p>
      <b class="text-accent">For Operator Accounts</b><br><br>
      <p>If you are an operator and decided to delete your account or pull out your vehicle permanently, please contact Carbnb to process your pull out. Here are the process to have your account removed:</p>
      <ol>
        <li>Contact your Carbnb Onboarding Officer or go to our main office at 111 E. Aguinaldo Avenue, AFPOVAI, Taguig City.</li>
        <li>Fill out the Operator's Pull Out Form or any process that your Onboarding Officer will be requiring.</li>
        <li>After completion of the required documents, our Onboarding Officer will be the one to permanently remove your account.</li>
      </ol>
        <q-form @submit="deleteAccount">
            If you have acknowledged all the terms and conditions above and want to continue to remove your account, enter your <b>Password</b> and click the <q-icon name="delete"/> Delete My Account button.<br><br>
            <div>
                <q-input
                    v-model="password"
                    outlined
                    type="password"
                    placeholder="Enter Password"
                    class="cb-input"
                    :error="passwordError != ''"
                    :error-message="passwordError"
                    lazy-rules
                    :rules="[(val) => (val && val.length > 0) || 'Your password is required to delete your account.']"
                    hint="To proceed to delete your account, please enter your password."
                />
            </div>
            <div class="text-center q-my-xl full-width row justify-between">
                <q-btn color="grey-7" class="q-mr-sm" icon="arrow_left" label="Go Back" to="/bookings"/>
                <q-btn color="primary" type="submit" icon="delete" label="Delete My Account"/>
            </div>
        </q-form>
    </section>
    <q-dialog
      v-model="showDeletionDialog"
      persistent
      maximized
    >
      <q-card class="column full-width justify-center items-center content-center">
        <q-card-section class="text-center">
            <q-spinner
                color="red"
                size="3em"
                :thickness="4"
                class="q-mb-lg"
            />
            <p class="text-h6 text-bold">We are sad to see you go!</p>
            <p>Please wait while we remove your account from our system.</p>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { notify } from "@/utils/notification";

const passwordError = ref('')
const showDeletionDialog = ref(false);
const password = ref('');
const router = useRouter();
const store = useStore();

/**
 * Sends a delete account request to the API.
 */
function deleteAccount() {
    showDeletionDialog.value = true;
    passwordError.value = '';
    store.dispatch('user/deleteUser', {password: password.value}).then(() => {
      store.commit("logout");
      store.commit("user/logout");
      store.commit("booking/logout");
      notify("positive", "Your account has been successfully removed from our system.");
      router.push('/');
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
          if (error.response.data.status == 'invalid_password') {
            passwordError.value = 'The password you entered is incorrect.';
          } else if (error.response.data.status == 'user_operator') {
            notify("warning", "Your account is an operator account. For pull out or account deletion, please contact your Carbnb Onboarding Officer.");
          }
        } else {
          notify("negative", "There has been an error while trying to delete your account. Please try again later.");
        }
      } else {
        notify("negative", "There has been an error while trying to delete your account. Please try again later.");
      }
    }).finally(() => {
      setTimeout(() => {
        showDeletionDialog.value = false;
      }, 1000);
    });
}
</script>
