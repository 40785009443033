<template>
  <section>
    <div class="container">
      <div class="row">
        <div
          v-for="(article, ind) in articles"
          :key="ind"
          class="col-12 col-md-4 q-pa-md"
        >
          <q-card>
            <q-card-section class="q-pa-none">
              <q-img class="card-image" :src="article.thumbnail" :fit="cover" />
            </q-card-section>

            <q-card-section class="card-content">
              <h6 class="text-accent">{{ article.title }}</h6>
              By <b>{{ article.author }}</b>
              <br />
              <small v-html="article.content"></small>
            </q-card-section>

            <q-card-actions class="q-py-md" align="center">
              <a
                :href="article.cta_url"
                target="_blank"
                >{{ article.cta_text }}</a
              >
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const articles = ref([]);

function fetchArticles() {
  store
    .dispatch("posts/fetchArticles")
    .then((response) => {
      articles.value = response.models.articles;
    })
    .catch(() => {})
    .finally(() => {});
}

fetchArticles();
</script>
<style scoped>
.btn-actions {
  min-width: 250px;
  border-radius: 15px;
  height: 50px;
}
.card-content {
  min-height: 220px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-image {
  min-height: 300px;
  max-height: 300px;
  max-width: inherit;
}
.card-image-latest {
  min-height: 400px;
  max-height: 400px;
}
.my-card {
  width: 100%;
  max-width: 272px;
}
.my-card-latest {
  width: 100%;
  max-width: 100%;
}
.focused-card {
  width: 700px;
  max-width: 80vw;
}
</style>
