<template>
  <section>
    <div class="row" style="min-width: 300px;">
        <div class="col-12">
          <div class="column">
            <div class="q-py-sm">
              <span class="text-h6">Set Duration</span>
              <p>Please set how many days would you like to rent the vehicle?</p>
              <q-btn-group rounded spread>
                <q-btn size="lg" glossy icon="remove" @click="handleDecrement"/>
                <q-btn size="md" glossy :label="durationLabel" @click="handleMultipleIncrement" />
                <q-btn size="lg" glossy icon-right="add" @click="handleIncrement"/>
              </q-btn-group>
              <q-toggle
                v-model="enable12Hour"
                color="primary"
                label="Add 12 hours to the duration."
                class="q-mt-md"
                @update:model-value="handleHalfdayToggle"
              />
              <q-separator class="q-my-md"></q-separator>
              <div class="column text-center">
                <span class="text-subtitle1">You return on</span>
                <span class="text-subtitle2">{{ returnDateLabel }}</span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue';
import { useStore } from "vuex";

const store = useStore();

const enable12Hour = ref(store.state.search.returnDate.hasHalfDay);
const duration = ref(store.state.search.returnDate.duration);
const durationLabel = ref(store.state.search.returnDate.durationLabel);
const returnDateLabel = computed(() => store.state.search.returnDate.label);
const emits = defineEmits(['update']);

updateDurationLabel();

function handleIncrement() {
  let currentValue = duration.value;
  currentValue += 1;

  if (currentValue > 30) {
    duration.value = 30;
    commitDuration();

    return;
  }

  duration.value += 1;

  commitDuration();
}

function handleDecrement() {
  let currentValue = duration.value;
  currentValue -= 1;

  if (currentValue <= 0) {
    duration.value = 1;
    commitDuration();

    return;
  }

  duration.value -= 1;
  commitDuration();
}

function handleMultipleIncrement() {
  let currentValue = duration.value;
  currentValue += 2;

  if (currentValue > 30) {
    duration.value = 30;
    commitDuration();
    return;
  }

  duration.value += 2;
  commitDuration();
}

function handleHalfdayToggle() {
  commitDuration();
}

function commitDuration() {
  var value = {
    duration: duration.value,
    hasHalfDay: enable12Hour.value
  };
  store.commit('search/setReturnDate', value);
  emits('update', value)
  updateDurationLabel();
}

function updateDurationLabel() {
  var duration = store.state.search.returnDate.duration;
  var hasHalfDay = store.state.search.returnDate.hasHalfDay;
  var label = '';

  if (duration == 1) {
    label = duration + ' day';
  } else {
    label = duration + ' days';
  }

  if (hasHalfDay == true) {
    label = label + ' and 12 hours';
  }

  durationLabel.value = label;
  store.commit('search/setDurationLabel', durationLabel.value);
}
</script>