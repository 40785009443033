<template>
  <section style="min-height: 600px;">
    <div class="lt-md">
      <q-dialog v-model="dialog" position="top">
        <q-card>
          <q-card-section>
            <SearchEngine :sidebar="true" v-on:searchButtonClick="dialog = !dialog"/>
          </q-card-section>
        </q-card>
      </q-dialog>
      <div class="q-pa-sm">
        <q-btn class="btn-primary" color="primary" label="Preferences" icon-right="search" @click="dialog = true"/>
      </div>
    </div>
    <div class="gt-sm">
      <SearchEngine :searchOnClick="true"/>
    </div>
    <q-scroll-area ref="vehicleList" style="height: 95vh">
      <div class="row" v-if="!isLoading && vehicles.length > 0">
        <div class="col-12 col-sm-4 col-md-2 q-pa-xs" v-for="vehicle in vehicles" v-bind:key="vehicle.id">
          <VehicleCardComponent :data="vehicle"/>
        </div>
      </div>
      <div class="row" v-if="isLoading">
        <div class="col-12 col-sm-4 col-md-2 q-pa-xs" v-for="i in 10" v-bind:key="i">
          <q-card>
            <q-skeleton height="350px" square />
            <q-card-section>
              <q-skeleton type="text" class="text-subtitle1" />
              <q-skeleton type="text" class="text-subtitle1" />
              <q-skeleton type="text" class="text-caption" />
            </q-card-section>
            <q-card-section>
              <q-skeleton type="text" class="text-subtitle1" />
              <q-skeleton type="text" class="text-subtitle1" />
              <q-skeleton type="text" class="text-caption" />
            </q-card-section>
            <q-card-section>
              <q-skeleton type="QBtn" style="width: 100%; height: 50px" />
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="q-pa-lg flex flex-center">
        <q-pagination
          v-model="currentPage"
          :max="meta.number_of_pages"
          size="md"
          :max-pages="5"
          @update:model-value="onPageSelect"
        />
      </div>
      <div style="height: 50vh;" v-if="vehicles.length == 0">
        <div class="row justify-center items-center" style="height: 100%; width: 100%;">
          <div class="q-pa-sm text-center">
            <q-icon name="sentiment_very_dissatisfied" class="text-h2 text-secondary"></q-icon>
            <h5 class="text-primary">No Vehicles Available</h5>
            <p class="text-subtitle2">Unfortunately, there are no vehicles found that is available within your search.</p>
            <p class="text-subtitle2 text-grey-6">Try changing your search criteria or try contacting our sales agent to assist you even more. Maybe there is something available on a very last minute.</p>
          </div>
        </div>
      </div>
    </q-scroll-area>
  </section>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import VehicleCardComponent from "../components/VehicleCardComponent.vue";
import SearchEngine from "../components/SearchEngine.vue";

const store = useStore();
const isLoading = computed(() => store.state.search.isLoading);
const meta = computed(() => store.state.search.meta);
const vehicles = computed(() => store.state.search.vehicles);
const vehicleList = ref(null);
const currentPage = computed(() => store.state.search.currentPage);
const dialog = ref(false);
onMounted(() => {
  // TODO: Slowing down detected when book now is pressed
  store.dispatch('search/getAvailableVehicles');
});

function onPageSelect(page) {
  store.commit('search/setCurrentPage', page);
  store.dispatch('search/getAvailableVehicles').finally(() => {
    window.scrollTo(0, 0);
    vehicleList.value.setScrollPercentage('vertical', 0);
  });
}
</script>
<style>
/* Entering and leaving transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>