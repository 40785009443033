import script from "./UserDropdownMenuComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./UserDropdownMenuComponent.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnDropdown,QAvatar,QList,QItem,QItemSection});qInstall(script, 'directives', {Ripple,ClosePopup});
