<template>
  <div class="flex justify-center q-pa-sm">
    <q-form class="cb-form" @submit="resendRequest" v-if="state == 'email_require'">
      <div class="q-px-xl q-pb-xl mobileLessPadding">
        <div>
          <div class="full-width text-h6 text-bold text-accent"><q-icon name="email"/> Enter Email</div>
          <p>Enter the email address of your account to receive a reset password code.</p>
          <q-input
            v-model="email"
            filled
            class="cb-input q-py-md"
            placeholder="Email Address"
            :disable="isLoading"
            :error="emailError != ''"
            :error-message="emailError"
            :rules="[(val) => (val && val.length > 0) || 'Email address is required.']"
          />
        </div>
        <q-btn
          class="btn-primary"
          type="submit"
          icon-right="send"
          label="Send"
          :loading="isLoading"
        />
      </div>
    </q-form> 
    <q-form class="cb-form" @submit="sendVerificationCode" v-if="state == 'code_sent'">
      <div class="q-px-xl q-pb-xl mobileLessPadding">
        <div>
          <div class="full-width text-accent text-h6 text-bold"><q-icon name="keyboard"/> Enter Code</div>
          <p class="q-my-none">
              Enter the 6-digit code that was sent on <b>{{ email }}</b>.<br><br>
              If you do not receive an email after 5 minutes or more, please click Resend Code.
          </p>
            <q-input
              v-model="code"
              filled
              class="cb-input q-py-md"
              placeholder="6-digit Code"
              maxlength="6"
              :error="codeError != ''"
              :error-message="codeError"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Please enter the 6-digit code found on your email.']"
            />
        </div>
        <q-btn
          class="btn-primary"
          type="submit"
          icon-right="send"
          label="Submit Code"
          :loading="isLoading"
        />
        <q-btn
          class="btn-primary"
          icon="restore"
          label="Change Email"
          color="secondary"
          :disable="isLoading"
          @click="changeEmail"
        />
          <div
            class="q-pt-sm text-center text-subtitle2 text-weight-regular"
          >
            Did not receive any code?
            <span v-if="countDown > 0">({{ countDown }})</span>
            <q-btn
              dense
              flat
              :disable="countDown > 0 ?? true"
              @click="resendRequest()"
              color="primary"
              class="q-py-none"
              >Resend Code</q-btn
            >
          </div>
      </div>
    </q-form>
    <q-form class="cb-form" @submit="finalizeChangePassword" v-if="state == 'input_password'">
      <div class="q-px-xl q-pb-xl mobileLessPadding">
        <div>
          <div class="full-width text-accent text-h6 text-bold"><q-icon name="lock"/> Set New Password</div>
          <p  class="q-my-none">
              Please set a new password to be used on your account.<br><br>
              For maximum security, we advise the following:
              <ol>
                <li>The password should have at least 8 characters.</li>
                <li>Use combinations of letters and numbers.</li>
                <li>Add special characters and at least 1 upper case.</li>
              </ol>
            </p>
            <q-input
              v-model="password"
              type="password"
              filled
              class="cb-input q-py-md"
              placeholder="New Password"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Enter a password for your account.']"
            />
            <q-input
              v-model="cpassword"
              type="password"
              filled
              class="cb-input q-py-md"
              placeholder="Repeat Password"
              :disable="isLoading"
              :rules="[(val) => (val && val.length > 0) || 'Please repeat the password you entered.']"
              :error="pworderr != ''"
              :error-message="pworderr"
            />
        </div>
        <q-btn
          class="btn-primary"
          type="submit"
          icon="save"
          label="Save Password"
          :loading="isLoading"
        />
      </div>      
    </q-form>       
  </div>
</template>

<script setup>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import { notify } from "@/utils/notification";
  import { useRouter } from "vue-router";

  const email = ref('');
  const state = ref('email_require');
  const isLoading = ref(false);
  const store = useStore();
  const emailError = ref('');
  const countDown = ref(0);
  const code = ref();
  const codeError = ref('');
  const password = ref();
  const cpassword = ref();
  const pworderr = ref('');
  const router = useRouter();

  /**
   * Send password reset request to server.
   */
  function resendRequest() {
    isLoading.value = true;
    store.dispatch("user/forgotPasswordRequest", {
      email: email.value
    }).then((response) => {
      switch (response.data.status) {
        case 'success':
          state.value = 'code_sent'
          countDown.value = 300;
          activateCountdownTimer();
          break;
      }
    }).catch((error) => {
      if (error.response !== undefined) {
        switch (error.response.status) {
          case 400:
            if (error.response.data.status == 'email_required') {
              emailError.value = 'Please enter your email address.';
            } else if (error.response.data.status == 'email_invalid') {
              emailError.value = 'Please enter a valid email address format.';
            }
            break;
          case 404:
            emailError.value = 'This email address does not exist on our system.';
            break;
          default:
            notify("negative", "There has been an error while processing your request. Please try again.");
            break;
        }
      }
    }).finally(() => {
      isLoading.value = false;
    });
  }

  /**
   * Sends the 6-digit code for password request.
   */
  function sendVerificationCode() {
    isLoading.value = true;
    store.dispatch("user/forgotPasswordUpdate", {
      email: email.value,
      key: code.value,
      mode: 'pre-verify'
      }).then(() => {
        state.value = 'input_password';
      }).catch((error) => {
      if (error.response !== undefined) {
        switch (error.response.status) {
          case 400:
            if (error.response.data.status == 'incorrect_code') {
              codeError.value = 'The code you entered is incorrect.';
            } else if (error.response.data.status == 'expired_code') {
              codeError.value = 'The code that you entered has expired. Please try resending again.';
            }
            break;
          case 404:
            notify('negative', 'The email address does not exist on our system.');
            state.value = 'email_require';
            email.value = '';
            code.value = '';
            break;
        }
      }
    }).finally(() => {
      isLoading.value = false;
    });
  }

  /**
   * The final call for changing password.
   */
  function finalizeChangePassword() {
    if (password.value.length < 8) {
      pworderr.value = 'Password must be at least 8 characters long.';
      return;
    }

    if (password.value != cpassword.value) {
      pworderr.value = 'The new password and repeat password must match. Please try again.';
      return;
    }
  
    isLoading.value = true;
    pworderr.value = '';
    store.dispatch("user/forgotPasswordUpdate", {
      email: email.value,
      key: code.value,
      password: password.value,
    }).then(() => {
        notify("positive", "You have successfully changed your password. Please try signing in again.");
        router.push('/');
    }).catch((error) => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 400:
          if (error.response.data.status === 'expired_code') {
            notify("negative", "The code has already expired. Please try sending a new code.");
            state.value = 'email_require';
          } else if (error.response.data.status === 'incorrect_code') {
            notify("negative", "The code that you entered is incorrect. Please try again.");
          } else if (error.response.data.status === 'invalid_password_length') {
            pworderr.value = 'Password must be at least 8 characters long.';
          }
          break;
        case 404:
          notify("negative", "The email address was not found. Please try again or create an account using it.");
          state.value = 'email_require';
          break;
        default:
          notify("negative", "There has been an error while trying to send verification to that email address. Please try again later.");
          break;
      }
    }
  }).finally(() => {
    isLoading.value = false;
  })
  }

  /**
   * Activates the countdown timer.
   */
  function activateCountdownTimer() {
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value = countDown.value - 1;
          activateCountdownTimer();
        }, 1000);
    }
  }
</script>

<style scoped>
.cb-form {
  max-width: 600px;
  min-height: 400px;
}
</style>