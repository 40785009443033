<template>
    <q-card>
      <q-img :src="thumbnail" @error="onImageError">
        <div class="absolute-bottom text-subtitle2">
        {{ props.data.vehicle_brand }} {{ props.data.vehicle_name }}
        </div>
      </q-img>

      <q-card-section>
        <div class="row">
            <div class="col-12 q-py-xs">
            <div style="height: 50px; max-height: 50px">
                <span>
                    <q-icon name="location_on" class="text-accent text-subtitle2" size="sm"/>
                    {{ props.data.address }}
                </span>
            </div>
            </div>
            <div class="q-py-xs col-6">
                <span><q-icon name="directions_car" class="text-accent text-subtitle2" size="sm"/> {{ props.data.vehicle_type }}</span>
            </div>
            <div class="q-py-xs col-6">
                <span><q-icon name="airline_seat_recline_normal" class="text-accent text-subtitle2" size="sm"/> {{ props.data.number_of_seats }} seater</span>
            </div>
            <div class="q-py-xs col-6">
                <span><q-icon name="local_gas_station" class="text-accent text-subtitle2" size="sm"/>{{ props.data.fuel_type_id }}</span>
            </div>
            <div class="q-py-xs col-6">
                <span><q-icon name="settings_applications" class="text-accent text-subtitle2" size="sm"/> {{ props.data.transmission_id }}</span>
            </div>
        </div>
        <q-separator class="q-my-sm"></q-separator>
        <div class="row">
            <div class="q-py-xs col-6">
                <span><q-icon name="schedule" class="text-accent text-subtitle2" size="sm"/> {{ props.data.pricing.duration }}</span>
            </div>
            <div class="q-py-xs col-6 text-bold">
                <span> {{ props.data.pricing.base_rate }}</span>
            </div>
        </div>
        <q-separator class="q-my-sm"></q-separator>
        <div class="row">
            <div class="q-py-xs col-6">
                <span>Other charges</span>
            </div>
            <div class="q-py-xs col-6">
                <span>+ {{ props.data.pricing.other_charges_fee }}</span>
            </div>
            <div class="q-py-xs col-6">
                <span>Total Rental Fee</span>
            </div>
            <div class="q-py-xs col-6">
                <span>{{ props.data.pricing.total_rental_fee }}</span>
            </div>
            <div class="q-py-xs col-6">
                <span>Reserve at</span>
            </div>
            <div class="q-py-xs col-6 text-bold text-positive">
                <span>{{ props.data.pricing.reservation_fee }}</span>
            </div>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn class="btn-primary" label="Book Now" :to="url"/>
      </q-card-actions>
    </q-card>
</template>
<script setup>
import { defineProps, ref } from "vue";

const thumbnail = ref(props.data.thumbnail);
const url = ref('/vehicle/' + props.data.slug);
const props = defineProps({
    data: {
        type: Object,
        required: true,
    }
});

function onImageError() {
    thumbnail.value = '/' + props.data.vehicle_type + '.webp';
}
</script>