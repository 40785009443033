<template>
    <section>
        <loading-screen-component message="Checking Pull Outs.." v-if="isLoading"/>
        <div v-else>
            <div class="flex justify-between items-center">
                <div class="flex items-center q-py-md">
                    <q-icon name="list_alt" size="xl" class="q-mr-sm gt-sm"/>
                    <div>
                        <span class="text-h6 text-bold text-accent">Pull Outs</span>
                        <br>
                        <span>Create a pull out request if you wish to block dates to use your vehicle.</span>
                    </div>
                </div>
                <div class="q-py-md">
                    <q-btn 
                        class="btn-primary"
                        icon="list_alt"
                        label="Create Request"
                        color="primary" 
                        @click="isPullOutVehicleDialogShown = !isPullOutVehicleDialogShown"
                        :disable="hasPendingApplication"
                        style="max-width: 250px"
                    >
                </q-btn>
                </div>
            </div>
            <div>
                <div class="q-py-md">
                    <span class="text-bold text-accent">List of Pull Outs</span>
                </div>
                <q-scroll-area style="height: 500px; min-width: 300px;">
                    <q-list bordered>
                        <q-item v-for="pullOut in pullOutData" v-bind:key="pullOut.id">
                            <q-item-section>
                                <q-item-label>
                                    <q-badge color="warning" label="Pending" v-if="pullOut.status == 'Pending'" />
                                    <q-badge color="teal" label="Approved" v-else-if="pullOut.status == 'Approved'" />
                                    <q-badge color="positive" label="Active" v-else-if="pullOut.status == 'Released'" />
                                    <q-badge color="danger" label="Declined" v-else-if="pullOut.status == 'Declined'" />
                                    <q-badge color="secondary" :label="pullOut.status" v-else />
                                </q-item-label>
                                <q-item-label class="text-bold">{{ pullOut.reason }}</q-item-label>
                                <q-item-label caption>{{ pullOut.start_date }}</q-item-label>
                                <q-item-label caption>{{ pullOut.end_date }}</q-item-label>
                                <q-item-label caption>Reason: {{ pullOut.remarks }}</q-item-label>
                            </q-item-section>
                            <q-item-section side v-if="pullOut.status == 'Approved' || pullOut.status == 'Pending'">
                                <q-btn size="12px" flat dense icon="delete" label="Cancel" @click="cancel(pullOut.id)" />
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-scroll-area>
            </div>
        </div>
        <pull-out-request-form-dialog :show="isPullOutVehicleDialogShown"
            v-on:close="handlePullOutDialogClose(false)"
            v-on:success="handlePullOutDialogClose(true)"/>
    </section>
</template>

<script setup>
import LoadingScreenComponent from '@/components/LoadingScreenComponent'
import PullOutRequestFormDialog from '../Dialogs/PullOutRequestFormDialog.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import { notify } from '../../../utils/notification';

const store = useStore();
const isLoading = ref(false);
const isPullOutVehicleDialogShown = ref(false);
const vehicle = computed(() => store.state.operator.vehicle);
const pullOutData = ref(null);
const $q = useQuasar();

get();

function get() { 
    isLoading.value = true;
    store.dispatch("getPullOutData", vehicle.value.id).then((response) => {
        isLoading.value = false;
        if (response.data.data.pull_outs.length > 0) {
            pullOutData.value = response.data.data.pull_outs;
        }
    }).catch((e) => {
        console.error(e);
    }).finally(() => {
        isLoading.value = false;
    });
}

function cancel(id) {
    $q.dialog({
        title: 'Cancel Pull Out?',
        message: 'The vehicle will be open for booking on these dates. Are you sure you want to cancel this pull out?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        proceedCancel(id)
      }).onOk(() => {
        //
      }).onCancel(() => {
        // console.log('>>>> Cancel')
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
}

function proceedCancel(id) {
    isLoading.value = true;
    var payload = {
        pullout_id: id
    };
    store.dispatch("cancelPullOutData", payload).then(() => {
        notify(
            "positive",
            "The request has been successfully cancelled."
        );
    }).catch((error) => {
        if (error.response !== undefined) {
            const errorMessage = error.response.data.data?.message;
            notify("negative", errorMessage);
            console.error(errorMessage);
        } else {
            console.error(error);
        }
    }).finally(() => {
        get();
        isLoading.value = false;
    });
}

function handlePullOutDialogClose(refresh = false) {
    isPullOutVehicleDialogShown.value = false

    if (refresh) {
        get();
    }
}
</script>