<template>
    <q-dialog v-model="isPullOutVehicleDialogShown">
        <q-card style="min-width: 350px; width: 450px;">
            <q-card-section class="row items-center q-pb-none">
                <span class="text-h6 text-bold text-accent">Request for Pull Out</span>
                <q-space />
                <q-btn icon="close" flat round dense @click="onDialogClose(true)"/>
            </q-card-section>
            <q-card-section>
                <div class="q-py-xs">
                    <q-select 
                        filled 
                        v-model="pullOut.type" 
                        :options="typeOptions" 
                        :rules="[val => !!val || 'Please select if Temporary or Permanent.']"
                        :disable="isSending"
                        @update:model-value="updatePullOutType"
                        label="Select Temporary or Permanent" 
                    />
                </div>
                <div class="q-py-xs" v-if="pullOut.type">
                    <q-select 
                        filled 
                        v-model="pullOut.reason" 
                        :options="reasonOptions"
                        :rules="[val => !!val || 'Please select from the list.']"
                        :disable="isSending"
                        label="Select the type of reason" 
                    />
                </div>
                <div v-if="pullOut.type && pullOut.reason">
                    <div class="q-py-sm">
                        <span class="text-weight-medium">When will you pick up the vehicle?</span>
                        <date-picker-component :error="pullOut.start_date.error" v-on:select="onPickupDateSelect"/>
                    </div>
                    <div class="q-py-sm" v-if="pullOut.type.name == '0'">
                        <span class="text-weight-medium">When will you return the vehicle?</span>
                        <date-picker-component :error="pullOut.end_date.error" v-on:select="onReturnDateSelect"/>
                    </div>
                    <div class="q-py-sm">
                        <q-input
                            v-model="pullOut.remarks.value"
                            filled
                            type="textarea"
                            label="Any comments or notes to let our staffs know?"
                            :disable="isSending"
                            :error="pullOut.remarks.error != null"
                            :error-message="pullOut.remarks.error"
                        />
                    </div>
                    <div class="q-py-sm" v-if="generalError != null">
                        <q-banner class="bg-primary text-white">
                            <q-icon name="error_outline" size="sm"></q-icon> {{ generalError }}
                        </q-banner>
                    </div>
                </div>
            </q-card-section>
            <q-card-actions>
                <q-btn
                    color="primary"
                    label="Send Request"
                    icon="list_alt"
                    :loading="isSending"
                    class="btn-primary"
                    @click="sendPullOutRequest"
                    :disable="!canSubmit"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>  
</template>

<script setup>
import { computed, ref, defineProps, defineEmits } from 'vue';
import { pullOutTypeOptions, reasonTypeList } from '../../../core/pulloutsys';
import DatePickerComponent from '../../DatePickerComponent.vue';
import { useStore } from 'vuex';
import { notify } from '../../../utils/notification';

const isPullOutVehicleDialogShown = computed(() => props.show);
const vehicle = computed(() => store.state.operator.vehicle);
const store = useStore();

const pullOut = ref({
    type: null,
    reason: null,
    start_date: {
        value: null,
        error: null
    },
    end_date: {
        value: null,
        error: null        
    },
    remarks: {
        value: null,
        error: null
    }
});

const generalError = ref(null);

const typeOptions = ref(pullOutTypeOptions);
const reasonOptions = ref();
const canSubmit = computed(() => pullOut.value.type
    && pullOut.value.reason 
    && pullOut.value.start_date.value);

const props = defineProps({
    show: {
        type: Boolean,
        value: false,
        default: false
    }
});

const emits = defineEmits(['close', 'success']);

const isSending = ref(false);

function updatePullOutType() {
    reasonOptions.value = reasonTypeList.filter((reason) => reason.type == pullOut.value.type.name);
    pullOut.value.reason = null;
}

function onPickupDateSelect(datetime) {
    pullOut.value.start_date.value = datetime;
}

function onReturnDateSelect(datetime) {
    pullOut.value.end_date.value = datetime;
}

function onDialogClose(refresh) {
    pullOut.value.start_date.error = null;
    pullOut.value.end_date.error = null;
    pullOut.value.remarks.error = null;
    pullOut.value.type = null;
    pullOut.value.reason = null;
    pullOut.value.remarks.value = null;

    if (refresh) {
        emits('success');
    } else {
        emits('close');
    }
}

function sendPullOutRequest() {
    isSending.value = true;
    
    pullOut.value.start_date.error = null;
    pullOut.value.end_date.error = null;
    pullOut.value.remarks.error = null;
    generalError.value = null;

    var payload = {
        vehicle_id: vehicle.value.id,
        type: pullOut.value.type.name,
        reason: pullOut.value.reason.name,
        start_date: pullOut.value.start_date.value
    };

    if (payload.type == 0) {
        payload.end_date = pullOut.value.end_date.value
    } else {
        payload.withdrawal_date = pullOut.value.start_date.value
    }

    if (pullOut.value.remarks.value) {
        payload.remarks = pullOut.value.remarks.value;
    }

    store.dispatch("sendPullOutApplication", payload).then(() => {
        notify('positive', 'The pull out request for this vehicle has been successfully sent.');
        onDialogClose(true);
    }).catch((err) => {
        const errors = err.response.data.data.errors;
        errors.forEach((error) => {
            switch (error.key) {
                case 'start_date':
                    pullOut.value.start_date.error = error.message;
                    break;
                case 'end_date':
                    pullOut.value.end_date.error = error.message;
                    break;
                case 'remarks':
                    pullOut.value.remarks.error = error.message;
                    break;
                default:
                    generalError.value = error.message;
                    break;
            }
        });
    }).finally(() => {
        isSending.value = false;
    });
}
</script>