<template>
    <section>
        <q-list padding>
            <q-item clickable v-ripple to="/search">
                <q-item-section>
                Book a Vehicle
                </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/apply-as-operator">
                <q-item-section>
                Apply as Operator
                </q-item-section>
            </q-item>

            <q-item clickable v-ripple to="/our-policy">
                <q-item-section>
                Privacy Policy
                </q-item-section>
            </q-item>

            <q-item clickable v-ripple to="/about-us">
                <q-item-section>
                About Us
                </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/contact">
                <q-item-section>
                Contact Us
                </q-item-section>
            </q-item>
        </q-list>
    </section>
</template>