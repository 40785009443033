<template>
  <section>
    <div class="container">
      <div
        class="q-pa-md q-mb-md row justify-start content-stretch q-gutter-md"
      >
        <q-card
          class="my-card"
          :class="{ 'my-card-latest q-mb-lg': ind == 0 && $q.screen.gt.sm }"
          v-for="(newsItem, ind) in news"
          :key="ind"
          @click="openSelectedNews(newsItem)"
        >
          <q-card-section
            class="q-pa-none"
            v-if="ind == 0 && $q.screen.gt.sm"
            horizontal
          >
            <q-card-section class="col-5 flex flex-center q-pa-none">
              <q-img class="card-image-latest" :src="newsItem.thumbnail" />
            </q-card-section>

            <q-card-section class="q-pt-md card-content-latest">
              <div class="text-overline">{{ newsItem.publish_date }}</div>
              <div class="text-h5 q-mt-sm q-mb-xs">{{ newsItem.title }}</div>
              <div
                class="text-caption text-grey"
                v-html="newsItem.content"
              ></div>
            </q-card-section>
          </q-card-section>

          <q-card-section v-if="ind == 0 && $q.screen.lt.md" class="q-pa-none">
            <q-img class="card-image" :src="newsItem.thumbnail" :fit="cover">
              <div class="absolute-bottom">
                <div class="text-h6 ellipsis-2-lines">{{ newsItem.title }}</div>
                <div class="text-subtitle2">{{ newsItem.publish_date }}</div>
              </div>
            </q-img>

            <q-card-section
              class="card-content"
            >
              <div class="ellipsis-3-lines" v-html="newsItem.content"></div>
            </q-card-section>
          </q-card-section>

          <q-card-section v-if="ind > 0" class="q-pa-none">
            <q-img class="card-image" :src="newsItem.thumbnail" :fit="cover">
              <div class="absolute-bottom">
                <div class="text-h6 ellipsis-2-lines">{{ newsItem.title }}</div>
                <div class="text-subtitle2">{{ newsItem.publish_date }}</div>
              </div>
            </q-img>

            <q-card-section
              class="card-content"
              :class="{ 'card-content-latest': ind == 0 }"
            >
              <div class="ellipsis-3-lines" v-html="newsItem.content"></div>
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="isFocusNews">
      <q-card class="my-card">
        <q-img :src="selectedNews.thumbnail">
          <div style="width: 100%" class="row justify-end">
            <q-btn icon="close" flat round dense @click="closeSelectedNews()" />
          </div>
        </q-img>

        <q-card-section style="max-height: 50vh" class="q-pt-md scroll">
          <div class="text-overline">{{ selectedNews.publish_date }}</div>
          <div class="text-h5 q-mt-sm q-mb-xs">{{ selectedNews.title }}</div>
          <div
            class="text-caption text-grey"
            v-html="selectedNews.content"
          ></div>
        </q-card-section>

        <q-card-section class="q-pt-none"> </q-card-section>

        <q-separator />
      </q-card>
    </q-dialog>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const news = ref([]);
const selectedNews = ref({});
const isFocusNews = ref(false);

function openSelectedNews(newsItem) {
  isFocusNews.value = true;
  selectedNews.value = newsItem;
}

function closeSelectedNews() {
  isFocusNews.value = false;
  selectedNews.value = {};
}

function fetchNews() {
  store
    .dispatch("posts/fetchNews")
    .then((response) => {
      news.value = response.models.news;
    })
    .catch(() => {})
    .finally(() => {});
}

fetchNews();
</script>
<style>
.btn-actions {
  min-width: 250px;
  border-radius: 15px;
  height: 50px;
}
.card-content {
  min-height: 100px;
  max-height: 150px;
  max-width: inherit;
}
.card-content-latest {
  min-height: 380px;
  max-height: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-image {
  min-height: 300px;
  max-height: 300px;
  max-width: inherit;
}
.card-image-latest {
  min-height: 400px;
  max-height: 400px;
}
.my-card {
  width: 100%;
  max-width: 272px;
}
.my-card-latest {
  width: 100%;
  max-width: 100%;
}
.focused-card {
  width: 700px;
  max-width: 80vw;
}
</style>
