<template>
  <section class="q-pa-xs">
    <div class="flex justify-center" v-if="!isLoading">
      <q-card bordered style="max-width: 1200px; width: 800px">
        <q-card-section>
          <div class="column q-py-lg" v-if="booking.status == 'Waiting for Payment'">
            <span class="text-h6"><q-icon name="info" size="sm" class="text-blue"/> For Reservation!</span>
            <span class="text-grey-6">This vehicle is reserved for you in 3 hours. You have to pay for
              the reservation fee so that this vehicle will be confirmed to you.
            </span>
            <div class="q-pa-sm text-right">
              <q-btn class="btn-primary" color="warning" label="Cancel Booking"
              @click="showCancellationModal = !showCancellationModal"
              style="max-width: 300px"/>
            </div>
          </div>
          <div class="column q-py-lg" v-if="booking.status == 'For Release' && invoice == null">
            <span class="text-h6"><q-icon name="check_circle" size="sm" class="text-positive"/> Booking Confirmed!</span>
            <span class="text-grey-6">This booking is now confirmed and is set for release on {{ booking.estimated_return_date }}.<br>
              For any concerns regarding this booking, please do not hesitate to call us.
            </span>
          </div>
          <div class="column q-py-lg" v-if="(booking.status == 'For Release' || booking.status == 'Active') && extension">
            <span class="text-h6"><q-icon name="info" size="sm" class="text-warning"/>For Extension</span>
            <span class="text-grey-6">This booking has pending payment for extension of <b>{{ extension.extension_length }}</b>. <br>Please settle the extension fee to officially change your return date.
            </span>
          </div>
          <div class="column q-py-lg" v-if="booking.status == 'Cancelled'">
            <span class="text-h6"><q-icon name="event_busy" size="sm" class="text-negative"/>For Extension</span>
            <span class="text-grey-6">This booking is already cancelled.
            </span>
          </div>
          <div class="flex justify-between">
            <div class="text-left">
              <img style="max-width: 150px" :src="booking.vehicle_thumbnail"/>
            </div>
            <div class="text-right">
              <h4 style="line-height: 15px;">{{ booking.booking_code }}</h4>
              <p>Booking Code</p>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="column q-py-lg" v-if="booking.status == 'Active' && !invoice">
            <span class="text-h6"><q-icon name="info" size="sm" class="text-positive"/> Booking is Active</span>
            <q-list bordered separator>
              <q-item>
                <q-item-section avatar>
                  <q-avatar rounded>
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label caption class="text-secondary">Inspected and Released By</q-item-label>
                  <q-item-label>{{ booking.releasing_officer }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="agent != null">
                <q-item-section avatar>
                  <q-avatar rounded>
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label caption>Booking Agent</q-item-label>
                  <q-item-label>{{ booking.agent }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
         <BookingDetailsComponent :booking="booking" :extension="extension"/>
         <PriceBreakdownComponent :invoice="invoice" v-if="invoice"/>
        </q-card-section>
        <q-card-section>
          <div>
            <div class="q-pa-xs" v-if="invoice">
              <q-btn class="btn-primary" size="lg" @click="proceed">
                <template v-slot>
                    <div class="column">
                        <span>{{ buttonLabel }}</span>
                    </div>
                </template>
              </q-btn>
            </div>
            <div class="q-pa-xs">
              <q-btn class="btn-primary" color="secondary" size="lg" to="/bookings" label="Back to Bookings"/>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-dialog v-model="showCancellationModal" :persistent="isCancelling">
        <q-card style="min-width: 350px">
          <q-card-section>
            <div class="text-h6">Cancel Booking?</div>
            <p>We would love to know the reason of your cancellation that maybe we can work it out for you.</p>
          </q-card-section>
          <q-card-section>
            <q-select filled :disable="isCancelling" v-model="cancellationCategory.value"
              :options="cancellationOptions" emit-value
              map-options label="Select Category" class="q-my-sm" :error="cancellationCategory.error != null"
              :error-message="cancellationCategory.error"
            />
            <q-input filled :disable="isCancelling" v-model="cancellationReason.value"
              label="Can you tell us more?" class="q-my-sm" :error="cancellationReason.error != null"
              :error-message="cancellationReason.error"/>
          </q-card-section>
          <q-card-section>
            <div class="row">
              <div class="col-12 col-md-6 q-pa-xs">
                <q-btn class="btn-primary" :disabled="isCancelling" color="secondary" label="No" v-close-popup />
              </div>
              <div class="col-12 col-md-6 q-pa-xs">
                <q-btn class="btn-primary" :loading="isCancelling" label="Yes, cancel" @click="cancel"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <LoadingScreenComponent v-else/>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import LoadingScreenComponent from '@/components/LoadingScreenComponent';
import BookingDetailsComponent from '@/components/Booking/BookingDetailsComponent'
import PriceBreakdownComponent from '../components/Booking/PriceBreakdownComponent.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { notify } from '../utils/notification';

const isLoading = ref(true);
const isCancelling = ref(false);
const store = useStore();
const route = useRoute();
const router = useRouter();
const booking = ref(null);
const invoice = ref([]);
const payments = ref([]);
const extension = ref(null);
const buttonLabel = ref();
const showCancellationModal = ref(false);
const cancellationOptions = ref(store.state.system.cancellationReasons);

const cancellationCategory = ref({
  value: null,
  error: null
});

const cancellationReason = ref({
  value: null,
  error: null
});
//const user = ref(store.state.user.data);

store.dispatch('booking/get', route.params.id).then((data) => {
  booking.value = data.booking;
  invoice.value = data.invoices.invoice;
  payments.value = data.payments;

  if (invoice.value !== null) {
    if (invoice.value.type == 'Booking') {
      buttonLabel.value = 'Pay for ' + invoice.value.reservation_fee;
    } else {
      extension.value = data.invoices.booking_extension;
      buttonLabel.value = 'Extend for ' + invoice.value.reservation_fee;
    }
  }
}).catch(() => {
  router.push('/bookings');
}).finally(() => {
  isLoading.value = false;
})

function proceed() {
  router.push({
    name: 'Payment',
      params: {
        id: booking.value.id
      }
    });
}

function cancel() {
  isCancelling.value = true;
  cancellationCategory.value.error = null;
  cancellationReason.value.error = null;

  var payload = {
    booking_id: booking.value.id,
    reason: cancellationReason.value.value,
    cancellation_reason_id: cancellationCategory.value.value
  };

  store.dispatch('booking/cancel', payload).then(() => {
    notify('positive', 'The booking was successfully cancelled.');
    router.push('/bookings');
  }).catch((err) => {
    if (err.response) {
      if (err.response.status == 400) {
        const errors = err.response.data.data.errors;
        errors.forEach(error => {
          if (error.key == 'cancellation_reason_id') {
            cancellationCategory.value.error = error.message;
          } else  {
            cancellationReason.value.error = error.message;
          }
        });
      } else {
        notify('red', 'Sorry but we experienced some error. Please try again.');
      }
    } else {
      notify('red', 'Sorry but we experienced some technical error. We are working on fixing the issue.');
    }
  }).finally(() => {
    isCancelling.value = false;
  })
}
</script>

<style scoped>
.cell {
  border: 1px solid rgb(212, 212, 212); /* Adjust thickness, style, and color */
  line-height: 1;
}
</style>