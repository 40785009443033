import script from "./Search.vue?vue&type=script&setup=true&lang=js"
export * from "./Search.vue?vue&type=script&setup=true&lang=js"

import "./Search.vue?vue&type=style&index=0&id=dc9e197c&lang=css"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QBtn,QScrollArea,QSkeleton,QPagination,QIcon});
