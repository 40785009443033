import { convertDateTimeToHumanReadable, getDateToday } from "../core/datehelper";
import axios from 'axios';

const search = {
  state: () => ({
    yard: {
        id: null,
        name: 'All Location',
        address: ''
    },
    vehicle: {
        id: 2,
        name: 'Sedan',
        subtitle: 'Select seating capacity'
    },
    pickupDate: {
        label: 'Choose Pickup Date',
        value: null,
    },
    returnDate: {
        label: 'Set Return Date',
        value: null,
        duration: 1,
        hasHalfDay: false,
        durationLabel: '1 day'
    },
    references: {
        yards: [
            {
                name: 'Taguig City',
                address: 'Lot 111, E. Aguinaldo Ave., AFPOVAI, Taguig City, Metro Manila',
                id: 1
            },
            {
                name: 'Makati City',
                address: 'Frabella Corporate Plaza, Makati City',
                id: 2
            },
            {
                name: 'Quezon City',
                address: '52 Samonte, Novaliches, Quezon City, Metro Manila',
                id: 3
            },
        ],
        blockedDates: [
            '2024/10/26',
            '2024/12/11',
            '2024/12/24',
            '2024/12/25'
        ],
        vehicles: [
            {
                id: 1,
                name: 'Hatchback',
                subtitle: '4 seater vehicles',
                image: 'HATCHBACK.webp'
            },
            {
                id: 2,
                name: 'Sedan',
                subtitle: '5 seater vehicles',
                image: 'SEDAN.webp'
            },
            {
                id: 3,
                name: 'MPV',
                subtitle: '7 seater vehicles',
                image: 'MPV.webp'
            },
            {
                id: 4,
                name: 'Pickup',
                subtitle: '5 seater with truck bed',
                image: 'PICKUP.webp'
            },
            {
                id: 5,
                name: 'Van',
                subtitle: '12 to 18 seaters',
                image: 'VAN.webp'
            },
            {
                id: 6,
                name: 'SUV',
                subtitle: '8 to 9 seaters',
                image: 'SUV.webp'
            }
        ]
    },
    meta: {
        page: 1,
        number_of_pages: 0,
        total: 0,
        count: 0
    },
    currentPage: 1,
    vehicles: [],
    isLoading: false,
    currentSearchQuery: {
        location: '',
        pickupDate: '',
        returnDate: '',
        vehicleType: ''
    }
  }),
  actions: {
    getAvailableVehicles({ state, commit }, queryReset = false) {
        commit('setLoading', true);

        var payload = {
            pickup_date: state.pickupDate.value,
            estimated_return_date: state.returnDate.value,
            yard_id: state.yard.id,
            vehicle_type: state.vehicle.id
        };

        var page = state.currentPage;

        if (queryReset) {
            commit('setCurrentPage', 1);
            page = 1;
        }

        payload.page = page;

        commit('setCurrentSearchQuery', {
            pickupDate: state.pickupDate.label,
            returnDate: state.returnDate.label,
            location: state.yard.name,
            vehicleType: state.vehicle.name
        });

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/v1/vehicles`,
                method: "POST",
                data: payload
            })
            .then((response) => {
                commit('setAvailableVehicles', response.data.data);
                return resolve();
            })
            .catch(() => {
                return reject();
            })
            .finally(() => {
                commit('setLoading', false);

                return resolve();
            })
        });
    },
  },

  mutations: {
    setYards(state, data) {
        state.references.yards = data;
    },
    setBlockedDates(state, data) {
        state.references.blockedDates = data;
    },
    setYard(state, id) {
        var flag = 0;
        state.references.yards.forEach((yard) => {
            if (id == yard.id) {
                state.yard.id = yard.id;
                state.yard.name = yard.name;
                state.yard.address = yard.address;
                flag = 1;
                console.log('The selected yard has been set to ' + state.yard.name);
            }
        });

        if (flag == 0) {
            state.yard.name = 'Choose pickup location';
            state.yard.address = 'Where to pick up?';
            console.log('The selected yard was not found from the references.');
        }
    },
    setVehicle(state, id) {
        var flag = 0;
        state.references.vehicles.forEach((vehicle) => {
            if (id == vehicle.id) {
                state.vehicle.id = vehicle.id;
                state.vehicle.name = vehicle.name;
                state.vehicle.subtitle = vehicle.subtitle;
                flag = 1;
            }
        });

        if (flag == 0) {
            state.yard.name = 'Choose vehicle type';
            state.yard.address = 'Select seating capacity';
        }
    },
    setPickupDate(state, date) {
        console.log('The pick up date has been set to ' + date);
        state.pickupDate.value = date;
        state.pickupDate.label = convertDateTimeToHumanReadable(date);

        this.commit('search/setReturnDate', {
            duration: state.returnDate.duration,
            hasHalfDay: state.returnDate.hasHalfDay
        });
    },
    setReturnDate(state, {duration, hasHalfDay}) {
        state.returnDate.duration = duration;
        state.returnDate.hasHalfDay = hasHalfDay;

        let date = state.pickupDate.value;

        if (date == null) {
            date = new Date(getDateToday(4));
            state.pickupDate.value = date;
        }

        let returnDate = new Date(state.pickupDate.value);
        returnDate.setHours(returnDate.getHours() + (duration * 24));

        if (hasHalfDay) {
            returnDate.setHours(returnDate.getHours() + 12);
        }

        returnDate = new Date(returnDate);

        // Might be improved, not sure why this works but it fucking works anyway.
        const year = returnDate.getFullYear();
        const month = String(returnDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(returnDate.getDate()).padStart(2, '0');
        const hours = String(returnDate.getHours()).padStart(2, '0');
        const minutes = String(returnDate.getMinutes()).padStart(2, '0');
        const seconds = String(returnDate.getSeconds()).padStart(2, '0');

        returnDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        state.returnDate.value = returnDate;
        state.returnDate.label = convertDateTimeToHumanReadable(returnDate);
    },
    setDurationLabel(state, label) {
        state.returnDate.durationLabel = label;
    },
    setAvailableVehicles(state, data) {
        state.vehicles = data.vehicles;
        state.meta.page = data.page;
        state.meta.count = data.count;
        state.meta.number_of_pages = data.number_of_pages;
        state.meta.total = data.total;
        state.meta.yard_id = data.yard_id;
    },
    setLoading(state, value) {
        state.isLoading = value;
    },
    setCurrentPage(state, page) {
        state.currentPage = page;
    },
    setCurrentSearchQuery(state, data) {
        state.currentSearchQuery.pickupDate = data.pickupDate;
        state.currentSearchQuery.returnDate = data.returnDate;
        state.currentSearchQuery.location = data.location;
        state.currentSearchQuery.vehicleType = data.vehicleType;
    }
  },
  namespaced: true,
};

export default search;