<template>
  <div>
    <q-input
      v-if="addPromoCode && !isVoucherLegit"
      v-model="code"
      outlined
      placeholder="Promo Code"
      :error="codeErrorMessage != null"
      :error-message="codeErrorMessage"
      hint="Enter your promo code."
      :disable="isRedeeming"
    >
      <template v-slot:after>
        <q-btn
          icon-right="redeem"
          color="positive"
          label="Redeem"
          @click="redeem"
          :loading="isRedeeming"
          v-if="!isVoucherLegit"
        />
      </template>
    </q-input>
    <q-toggle
      v-model="addPromoCode"
      label="I have a promo code."
      @update:model-value="handleToggleChange"
      :disable="isRedeeming || isVoucherLegit"
      class="q-mt-lg"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useStore } from "vuex";
import axios from 'axios';

const addPromoCode = ref(false);
const code = ref();
const codeErrorMessage = ref(null);
const isRedeeming = ref(false);
const isVoucherLegit = ref(false);
const store = useStore();
const emits = defineEmits(['success', 'clear']);

function handleToggleChange() {
  code.value = '';
  isRedeeming.value = false;
  isVoucherLegit.value = false;
  codeErrorMessage.value = null;
  emits('clear', true);
}

function redeem() {
  if (code.value == '') {
    codeErrorMessage.value = 'Please enter a voucher code.';
    return;
  }

  codeErrorMessage.value = null;

  var payload = {
    vehicle_id: store.state.vehicle.data.id,
    pickup_date: store.state.search.pickupDate.value,
    estimated_return_date: store.state.search.returnDate.value,
    code: code.value
  };

  isRedeeming.value = true;
  axios({
    url: `/api/v1/vouchers`,
    method: "POST",
    data: payload
  })
  .then((response) => {
    if (response.data.status == 'success') {
      emits('success', code.value);
    }
  })
  .catch((err) => {
    if (err.response) {
      const response = err.response;
      if (response.status == 400) {
        const data = response.data;
        if (data.status == 'validation_errors') {
          const errors = data.data.errors;
          errors.forEach((error) => {
            codeErrorMessage.value = error.message;
          });
        }
      }
    }
  })
  .finally(() => {
    isRedeeming.value = false;
  })
}
</script>