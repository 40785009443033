import axios from "axios";

const user = {
  state: () => ({
    data: null,
    isLoggedIn: false,
    isSignInModalShown: false,
    isUserProfileShown: false,
    properties: {
      is_mobile_number_valid: false
    }
  }),

  getters: {
    getFullName: (state) => {
      if (state.data != null) {
        return state.data.first_name + " " + state.data.last_name;
      }
  
      return "Unknown";
    },
    getProfilePhoto: (state) => {
      if (state.data != null) {
        return state.data.photo_file;
      }

      return '/default_profile.jpg'
    },
    isOperator: (state) => {
      if (state.data != null) {
        if (state.data.type == 2) {
          return true;
        }
      }

      return false;
    }
  },

  actions: {
    signIn(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/users/sign-in",
          method: "POST",
          data: payload,
        }).then((response) => {
          switch (response.data.status) {
            case 'signed_in':
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("id", response.data.data.user.id);
              axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.data.user.id} ${response.data.data.token}`;
              this.commit('user/setUserData', response.data.data.user);
              this.commit('user/validateMobileNumber');

              return resolve(response.data.data);
            default:
              return resolve(response.data);
          }
        }).catch((err) => {
          reject(err)
        });
      });
    },
    fetchUserData({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setUserData", response.data.data.user);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    update(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users`,
          method: "PUT",
          data: payload,
        }).then((response) => {
          return resolve(response)
        }).catch((e) => {
          return reject(e)
        })
      });
    },
    requestUpdatePassword({ dispatch }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}/sendpasswordreset`,
          method: "GET"
        }).then((response) => {
          if (response.data.status == "success") {
            dispatch("fetchUserData",response.data.data.id);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    updatePassword({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/changepassword`,
          method: "POST",
          data: payload,
        }).then((response) => {
          commit('setHasPersonalizedPassword', 1);

          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    forgotPasswordRequest(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/forgotpassword`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        })
      });
    },
    forgotPasswordUpdate(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/forgotpassword/confirm`,
          method: "POST",
          data: payload,
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    },
    checkApplication({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/${payload}/apply-as-operator`,
          method: "GET",
        }).then((response) => {
            if (response.data.status == 'already_an_operator') {
              commit('setUserType', 2);
            }

            return resolve(response.data);
        }).catch(() => {
          return reject({
            status: null,
            vehicle_application_id: null
          });
        });
      });
    },
    deleteUser(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/delete`,
          method: "POST",
          data: payload
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        })
      });
    },
    fetchProvinces() {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/provinces`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            const provinces = response.data.data;

            return resolve({
              status: "success",
              data: provinces
            });
          }
        });
      });
    },
    fetchCities(_, provinceId) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/cities?provinceId=${provinceId}`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            const cities = response.data.data;

            return resolve({
              status: "success",
              data: cities
            });
          }
        });
      });
    },
    fetchBarangays(_, cityId) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/barangays?cityId=${cityId}`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            const barangays = response.data.data;

            return resolve({
              status: "success",
              data: barangays
            });
          }
        });
      });
    },
  
    /**
     * Checks the terms and conditions
     * @param { Object } param0 
     */
    checkTerms() {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/terms/check`,
          method: "GET",
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        });
      });
    },

    updateTerms(_, isConfirmed) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/terms/confirm`,
          method: "PUT",
          data: {
            isConfirmed
          },
        }).then((response) => {
          return resolve(response)
        }).catch((e) => {
          return reject(e)
        })
      });
    },
  },

  mutations: {
    /**
     * Validates the current mobile number that is saved on
     * this user.
     * @param { Object } state 
     */
    validateMobileNumber(state) {
      const regex = /^9\d{9}$/;
      state.properties.is_mobile_number_valid = regex.test(state.data.mobile_number);
    },
    updateCredits(state, credits) {
      state.data.credits = credits;
    },
    setUserData(state, payload) {
      state.data = payload;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.data = null;
      state.isLoggedIn = false;
      localStorage.removeItem("token");
      localStorage.removeItem("id");

      delete axios.defaults.headers.common["Authorization"];
    },
    setUserType(state, payload) {
      state.data.type = payload;
    },
    setMobileNumberVerifiedDate(state, date) {
      state.data.mobile_number_verified_at = date;
    },
    setHasPersonalizedPassword(state, value) {
      state.data.has_personalized_password = value;
    },
    showSignInModal(state) {
      state.isSignInModalShown = true;
    },
    hideSignInModal(state) {
      state.isSignInModalShown = false;
    },
    showProfileModal(state) {
      state.isUserProfileShown = true;
    },
    hideProfileModal(state) {
      state.isUserProfileShown = false;
    }
  },
  namespaced: true,
};

export default user;
