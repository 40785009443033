<template>
  <section>
    <div class="text-center q-py-lg" v-if="isLoading">
      <q-spinner
        color="primary"
        size="3em"
      />
      <p class="q-mt-md">Loading Terms and Conditions..</p>
    </div>
    <div v-else class="q-py-lg">
      <span><code>CARBNB TERMS AND CONDITIONS ({{ revision }}) as of {{ revisionDate }}</code></span>
      <div class="q-py-lg" v-html="html"></div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const isLoading = ref(true);
const html = ref();
const revision = ref();
const revisionDate = ref();

axios({
    url: '/api/v1/terms',
    method: "GET",
}).then((response) => {
  html.value = response.data.data.html;
  revision.value = response.data.data.revision;
  revisionDate.value = response.data.data.revision_date;
}).finally(() => {
  isLoading.value = false;
})
</script>