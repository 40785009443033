import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router'
import store from './store'
import quasarUserOptions from './quasar-user-options'
import globalstyle from './styles/global.css'
import mediaqueries from './styles/mediaqueries.css'
import { createMetaManager } from 'vue-meta'
import axios from 'axios'
import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(createMetaManager())

app.config.globalProperties.axios = axios

axios.defaults.baseURL = process.env.VUE_APP_APISERVER;

const token  = localStorage.getItem('token')
const user_id  = localStorage.getItem('id')

const Auth = `Bearer ${user_id} ${token}`
if (token){
    axios.defaults.headers.common["Authorization"] = Auth
}

app.use(Quasar, quasarUserOptions)
app.use(globalstyle)
app.use(mediaqueries)

app.AOS = new AOS.init({ 
    disable: "phone",
    once:true,
    duration:700, 
    easing: 'ease-in'
});

app.mount('#app')
