<template>
  <div class="container q-pa-md">
  <div class="row">
    <div class="col-12 q-pa-sm">
      <div class="q-py-sm">
        <span class="text-bold text-h6 text-accent">Operator Application Form</span>
      </div>
      <div class="text-subtitle1">What's your vehicle?</div>
      <q-input
        v-model="vehicle_name"
        filled
        class="cb-input"
        hint="For example: Honda Civic 2022"
        placeholder="Vehicle Name"
        :error="errors.vehicle_name != null"
        :error-message="errors.vehicle_name"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 col-xs-12 q-pa-sm">
      <div class="text-subtitle1">Plate Number</div>
      <q-input
        v-model="plate_number"
        filled
        class="cb-input"
        :error="errors.plate_number != null"
        :error-message="errors.plate_number"
      />
    </div>
    <div class="col-sm-6 col-xs-12 q-pa-sm">
      <div class="text-subtitle1">Fuel Type</div>
      <q-select
        v-model="fuel_type"
        filled
        :options="fuel_options"
        :options-label="fuel_options.value"
        class="cb-input"
        :error="errors.fuel_type != null"
        :error-message="errors.fuel_type"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-12 q-pa-xs">
      <div class="text-subtitle1">How do you want to manage your vehicle bookings?</div>
      <q-select
        v-model="managementStyle"
        filled
        :options="managementOptions"
        :options-label="managementOptions.value"
        class="cb-input"
        :error="errors.management_preference != null"
        :error-message="errors.management_preference"
      />
      <div v-if="managementStyle.value == 1">
        <q-select
          v-model="selectedYard"
          filled
          :options="yardList"
          class="cb-input"
          label="Select Location"
          :error="errors.yard_id != null"
          :error-message="errors.yard_id"
        />
      </div>
      <span class="text-caption" v-if="managementStyle.value == 1"><q-icon name="check" class="text-positive"/> Our Onboarding Officers will orient you regarding this option.</span>
      <div class="q-mt-md" v-if="managementStyle.value == 2">
        <div class="text-subtitle1">What's the address of your vehicle pickup location?</div>
        <q-input
          v-model="pickup_address"
          autogrow
          filled
          class="cb-input"
          placeholder="Pickup Address"
          hint="When selecting this option, you will be the one to manage your vehicle's booking and assisting clients on your pick up location."
          :error="errors.pickup_address != null"
          :error-message="errors.pickup_address"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <h6 class="col-sm-12 q-py-sm text-grey-7">Vehicle Photos</h6>
    <div class="col-12 q-py-xs">
      <p>Upload at least 1 photo of your vehicle with maximum of 3MB for each photos.</p>
      <p>We recommend to take the best photo of your vehicle and remove any filters for more professional output.</p>
      <p>Please note that our onboarding officers may ask for more photos of your vehicle including the interior.</p>
    </div>
    <div class="col-sm-12 q-py-xs">
      <q-file
        color="secondary"
        bg-color="white"
        filled
        counter
        v-model="gallery"
        multiple
        use-chips
        max-files="10"
        label="Click to Upload Photos"
        class="img-uploader q-mb-sm full-width"
        :error="errors.vehicle_photos != null"
        :error-message="errors.vehicle_photos"
      >
        <template v-slot:prepend>
          <q-icon name="image" />
        </template>
      </q-file>
    </div>
  </div>

  <div class="row">
    <h6 class="col-sm-12 q-py-sm text-grey-7">Document Files</h6>
    <div class="col-xs-12 q-py-sm">
    <p>Please separate the <b>Official Receipt of Registration</b> and the <b>Car Registration Certificate</b>.<br>
    If the vehicle is not named after you, please include a <b>Deed of Sale</b> and/or <b>Letter of Authorization</b> below.</p>
    <p>If you do not have the a digital copy or photo of your files, you can temporarily skip this part but our onboarding officers will ask for it while your onboarding process is ongoing.</p>
    </div>
    <div class="col-sm-6 col-xs-12 q-pa-xs">
      <div class="text-subtitle1">Official Receipt</div>
      <q-file
        v-model="or_file"
        filled
        class="cb-input"
        clearable
        label="Click to upload file"
        :error="errors.or_file != null"
        :error-message="errors.or_file"
      />
    </div>
    <div class="col-sm-6 col-xs-12 q-pa-xs">
      <div class="text-subtitle1">Car Registration Certificate</div>
      <q-file
        v-model="car_cert_file"
        filled
        class="cb-input"
        clearable
        label="Click to upload file"
        :error="errors.cr_file != null"
        :error-message="errors.cr_file"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-xs-12 q-pa-xs">
      <div class="text-subtitle1">Deed of Sale/Letter of Authorization</div>
      <q-file
        v-model="deed_of_sale"
        filled
        class="cb-input"
        clearable
        label="Click to upload file"
        hint="If the vehicle is not named after you, please include a Deed of Sale and/or Authorization Letter."
        :error="errors.deed_of_sale != null"
        :error-message="errors.deed_of_sale"
      />
    </div>
    <div class="col-sm-6 col-xs-12 q-pa-xs" v-if="forApplication">
      <div class="text-subtitle1">2 Valid IDs</div>
      <q-file
        v-model="valid_ids"
        filled
        class="cb-input"
        clearable
        multiple
        :rules="[(val) => (val && val.length > 1) || 'Atleast 2 valid IDs']"
        label="Click to upload file"
        :error="errors.valid_ids != null"
        :error-message="errors.valid_ids"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12 q-pa-md text-right">
      <div style="max-width: 400px; margin: auto">
        <q-btn
          color="primary"
          class="btn-primary"
          label="Submit Application"
          icon-right="send"
          @click="onSubmit"
          :loading="isLoading"
        />
      </div>
    </div>
  </div>
  <q-dialog v-model="isLoading" persistent>
    <q-card>
      <q-card-section class="text-center">
        <q-spinner
          color="red"
          size="3em"
          :thickness="4"
          class="q-mb-lg"
        />
        <p class="text-h6 text-bold">Submitting Application..</p>
        <p>Please wait while we process your application.</p>
      </q-card-section>
    </q-card>
  </q-dialog>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref } from "vue";
import carJson from "../../assets/json/vehicle.json";
import { notify } from "../../utils/notification";
import { useRouter } from "vue-router";
import { defineProps, defineEmits } from "vue";
import { useQuasar } from 'quasar';

const props = defineProps({
  forApplication: Boolean,
  forAddVehicle: Boolean,
});

const emits = defineEmits(['success']);
const store = useStore();
const $q = useQuasar();
// const user = computed(() => store.state.user.data);
const router = useRouter();
const vehicle_name = ref();
const fuel_options = carJson.fuel_options;
const plate_number = ref();
const fuel_type = ref();
const pickup_address = ref();
const car_cert_file = ref();
const deed_of_sale = ref();
const or_file = ref();
const gallery = ref([]);
const valid_ids = ref([]);
const isLoading = ref(false);
const managementStyle = ref({ value: 1, label: 'Let Carbnb manage my vehicle and park it on their yard.'});
const managementOptions = [
  { value: 1, label: 'Let Carbnb manage my vehicle and park it on their yard.'},
  { value: 2, label: 'I will manage my vehicle and will just enter a pick up address.'}
];

// Load the yard list and format it accordingly.
var yards = store.state.search.references.yards;
var yardList = [];

yards.forEach(function(yard) {
  yardList.push({label: yard.name, value: yard.id});
});

const selectedYard = ref();

const errors = ref({
  or_file: null,
  cr_file: null,
  deed_of_sale: null,
  valid_ids: null,
  vehicle_photos: null,
  vehicle_name: null,
  plate_number: null,
  pickup_address: null,
  yard_id: null
});

function getBase64(file) {
  if (!file) {
    return;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Custom script to render errors on the vehicle form for the
// user to see.
function renderAllErrors(validationErrors) {
  validationErrors.forEach((error) => {
    if (error.name == 'or_file') {
      errors.value.or_file = error.message;
    }
    if (error.name == 'cr_file') {
      errors.value.cr_file = error.message;
    } 
    if (error.name == 'deed_of_sale') {
      errors.value.deed_of_sale = error.message;
    }
    if (error.name == 'valid_ids') {
      errors.value.valid_ids = error.message;
    }  
    if (error.name == 'vehicle_photos') {
      errors.value.vehicle_photos = error.message;
    } 
    if (error.name == 'vehicle_name') {
      errors.value.vehicle_name = error.message;
    }
    if (error.name == 'plate_number') {
      errors.value.plate_number = error.message;
    } 
    if (error.name == 'fuel_type') {
      errors.value.fuel_type = error.message;
    } 
    if (error.name == 'management_preference') {
      errors.value.management_preference = error.message;
    } 
    if (error.name == 'pickup_address') {
      errors.value.pickup_address = error.message;
    } 
  })
}

// Resets all the errors on the page.
function resetAllErrors() {
  errors.value = {
    or_file: null,
    cr_file: null,
    deed_of_sale: null,
    valid_ids: null,
    vehicle_photos: null,
    vehicle_name: null,
    plate_number: null,
    pickup_address: null
  };
}

async function onSubmit() {
  if (managementStyle.value.value == 1 && selectedYard.value == undefined) {
    notify('red', 'Please check the form for some errors.');
    errors.value.yard_id = 'Please select a location from the list.';
    return;
  }

  isLoading.value = true;

  const payload = {
    user_id: store.state.user.data.id,
    vehicle_name: vehicle_name.value ?? '',
    pickup_address: pickup_address.value,
    management_preference: managementStyle.value.value,
    plate_number: plate_number.value ?? '',
    fuel_type_id: fuel_type.value ? fuel_type.value.value : null,
    files: [],
    yard_id: selectedYard.value.value
  };
 
  for (let item of gallery.value) {
    await getBase64(item).then((data) => {
      payload.files.push({ file: data, file_name: item.name, type: 1 });
    });
  }

  for (let valid_id of valid_ids.value) {
    await getBase64(valid_id).then((data) => {
      payload.files.push({ file: data, file_name: valid_id.name, type: 2 });
    });
  }
  let orFile = null,
    crFile = null,
    deedOfSale = null

  // For Application
  if (or_file.value) {
    orFile = getBase64(or_file.value).then((data) => {
      payload.files.push({
        file: data,
        file_name: or_file.value.name,
        type: 3,
      });
    });
  }
  if (car_cert_file.value) {
    crFile = getBase64(car_cert_file.value).then((data) => {
      payload.files.push({
        file: data,
        file_name: car_cert_file.value.name,
        type: 4,
      });
    });
  }
  if (deed_of_sale.value) {
    deedOfSale = getBase64(deed_of_sale.value).then((data) => {
      payload.files.push({
        file: data,
        file_name: deed_of_sale.value.name,
        type: 5,
      });
    });
  }

  Promise.allSettled([orFile, crFile, deedOfSale]).then(() => {
    resetAllErrors();
    if (props.forApplication) {
      store.dispatch("vehicle/applyAsOperator", payload).then((response) => {
        if (response.status == "application_submitted") {
          notify(
            "positive",
            "Your application was successfully submitted. Please wait for approval."
          );
          isLoading.value = false;
          emits('success', true);
        }
      }).catch((errors) => {
        console.error('Carbnb rejected the vehicle application because of some errors.');
        renderAllErrors(errors.data.data.errors);
      }).finally(() => {
        isLoading.value = false;
      });
    } else {
      store.dispatch("vehicle/createVehicle", payload).then((response) => {
        if (response.status == "success") {
            $q.dialog({
              title: 'On Review',
              message: 'You have successfully applied your vehicle and our onboarding officers will review it. Please keep your lines open as we might have call you for more information. You will receive an email regarding the status of your application.'
            }).onOk(() => {
              router.push("/my-vehicles");
              isLoading.value = false;
            }).onCancel(() => {
              router.push("/my-vehicles");
              isLoading.value = false;
            }).onDismiss(() => {
              router.push("/my-vehicles");
              isLoading.value = false;
            })
        }
      }).catch((errors) => {
        console.error('Carbnb rejected the vehicle application because of some errors.');
        renderAllErrors(errors.data.data.errors);
      }).finally(() => {
        isLoading.value = false;
      });
    }
  });
  }

</script>
<style scoped>
.img-uploader {
  width: 100%;
  max-width: 350px;
  max-height: 300px;
}
</style>
