/**
 * Generates all the 24 hour time and returns an array.
 * An optional argument 'start' is used when you want to
 * generate all the 24 hour time from that specific time
 * only.
 *
 * @param { String } from 
 */
export function generate24HourTimesFrom(start = '00') {
    const times = [];
    const startingHour = parseInt(start, 10);
  
    for (let hour = startingHour; hour < 24; hour++) {
      const formattedHour = hour.toString().padStart(2, '0');
      times.push(parseInt(`${formattedHour}`));
    }
  
    return times;
}

/**
 * Converts a 24 hour string time into 12 hour format time.
 *
 * @param { String } time 
 * @returns 
 */
export function convert24HourToAMPM(time) {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;

    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
}

/**
 * Takes a Y-m-d HH:mm Javascript date time and only returns the
 * Y-m-d. If the datetime is null, it will return the current date.
 *
 * @param { String } datetime 
 */
export function getDateOnly(datetime = null) {
  if (datetime) {
    return datetime.split(" ")[0];
  }

  return getDateToday().split(" ")[0];
}

/**
 * Takes a Y-m-d HH:mm Javascript date time and only returns the
 * HH:mm. If the datetime is null, it will return the current time.
 *
 * @param { String } datetime 
 */
export function getTimeOnly(datetime = null) {
  if (datetime) {
    return datetime.split(" ")[1];
  }

  return getDateToday().split(" ")[0];
}

/**
 * Generates the complete date today. You can add hours to the current
 * date and time by addHours argument. It will return the date and time today
 * plus the hours you added.
 * 
 * @param { Number } addHours 
 */
export function getDateToday(addHours = 0) {
  var now = new Date();

  if (addHours > 0) {
    now = new Date(now.getTime() + addHours * 60 * 60 * 1000);
  }

  let result = formatDate(now);

  return result;
}

/**
 * Internal function used to convert now = new Date(now.getTime() + addHours * 60 * 60 * 1000);
 * into dashed format.
 *
 * @param { Date } date 
 * @returns 
 */
function formatDate(date) {

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * Gets the current 24 hour time today. You have an option to convert it into
 * 12-hour AMPM by setting the AMPM argument into true.
 * 
 * You can also add hours to it by using the argument addHours.
 *
 * @param { Number } addHours 
 * @param { Boolean } AMPM 
 * @returns { String }
 */
export function getTimeToday(addHours = 0, AMPM = false) {
  const today = getDateToday(addHours);
  const time = today.split(" ")[1];

  if (AMPM) {
    return convert24HourToAMPM(time);
  }

  return time;
}

/**
 * Converts a Y-m-d HH:mm Javascript date object into a October 23, 2024 at 1PM.
 * This is used on Carbnb's standard date display.
 *
 * @param { String } datetime 
 * @returns { String }
 */
export function convertDateTimeToHumanReadable(datetime) {
  let dateString = datetime;

  if (dateString === null) {
    dateString = getTimeToday();
  }
  const date = new Date(dateString.replace(" ", "T"));

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  const finalOutput = formattedDate;

  return finalOutput;
}