<template>
  <div>
    <div class="flex items-center q-py-md">
        <q-icon name="drive_eta" size="xl" class="q-mr-sm gt-sm"/>
        <div>
            <span class="text-h6 text-bold text-accent">Vehicle Details</span>
        </div>
      </div>
    <q-list>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="money" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.plate_number }}</span>
          <q-item-label>Plate Number</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="settings" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.transmission }}</span>
          <q-item-label>Transmission</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="local_gas_station" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.fuel_type }}</span>
          <q-item-label>Fuel</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="event_busy" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.coding_day }}</span>
          <q-item-label>Coding Day</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="location_on" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.address }}</span>
          <q-item-label>Pickup Address</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="calendar_today" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.onboarding_date }}</span>
          <q-item-label>Carbnb Onboarding Date</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <h6>Statistics</h6>
    <q-list>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="payments" />
        </q-item-section>

        <q-item-section>
          <span class="text-positive">PHP {{ vehicle.total_earnings }}</span>
          <q-item-label>Total Earnings</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="schedule" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent">{{ vehicle.total_hours }} hour(s)</span>
          <q-item-label>Total Rented Hours</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section avatar>
          <q-icon color="primary" name="book" />
        </q-item-section>

        <q-item-section>
          <span class="text-accent"
            >{{ vehicle.total_bookings_closed }} booking(s)</span
          >
          <q-item-label>Total Closed Bookings</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const vehicle = computed(() => store.state.operator.vehicle);
</script>
