<template lang="">
  <center>
    <q-carousel
      animated
      v-model="slide"
      arrows
      navigation
      infinite
      height="500px"
      style="max-width: 1600px"
      :thumbnails="true"
      autoplay
    >
      <q-carousel-slide :name="999" :img-src="require('@/assets/images/banners/default.jpg')"/>
      <q-carousel-slide :name="banner.id" :img-src="banner.url" v-for="banner in banners" v-bind:key="banner.id"/>
    </q-carousel>
  </center>
</template>
<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

const slide = ref(999)
const store = useStore();
const banners = ref(store.state.system.banners);

</script>