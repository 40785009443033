<template>
    <div>
        <div>
        <q-card class="full-width" flat>
        <q-card-section>
          <div>
            <h6>Client Details</h6>
            <q-item>
              <q-item-section>
                <q-item-label><h6 class="text-accent" style="margin: 0">{{ booking.client_name}}</h6></q-item-label>
                <q-item-label caption><q-icon name="email"/> {{ booking.email }}</q-item-label>
                <q-item-label caption><q-icon name="smartphone"/> test</q-item-label>
              </q-item-section>
            </q-item>
            <div>
              <h6>Booking Details</h6>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="location_on" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ booking.destination }}</span>
                    <q-item-label>Destination</q-item-label>
                    <q-item-label caption>The client's declared destination.</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="today" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ booking.pickup_date }}</span>
                    <q-item-label>Pick Up Date</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="event" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ booking.estimated_return_date }}</span>
                    <q-item-label>Return Date</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="event_note" />
                </q-item-section>

                <q-item-section>
                    <span class="text-accent">{{ booking.points }}</span>
                    <q-item-label>Rental Length</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="paid" />
                </q-item-section>

                <q-item-section>
                    <span class="text-positive">PHP {{ booking.operator_fee }}</span>
                    <q-item-label>Receivable Operator Fee</q-item-label>
                </q-item-section>
              </q-item>           
            </div>
          </div>
        </q-card-section>
      </q-card>
        </div>
        <div class="full-width text-center q-py-lg">
            <q-banner inline-actions class="text-white bg-red q-my-md" v-if="showLateNotice">
                This booking is already marked as late. Please contact Carbnb to coordinate for an extension or to manually stop this booking.
                <template v-slot:action>
                    <q-btn flat color="white" label="Confirm" @click="showLateNotice = !showLateNotice" />
                </template>
            </q-banner>
            <q-banner class="bg-positive text-white q-my-md" v-if="booking.has_parking">
              <q-icon name="info" size="16px"/> This vehicle is parked on a Carbnb Yard. Our PDI officers will manage the starting and stopping of this booking.
            </q-banner>
            <q-btn
                v-else
                color="primary"
                class="q-ml-md"
                icon="timer"
                label="Stop"
                @click="showStopBookingDialog = !showStopBookingDialog"
                :disabled="showLateNotice"
            />
        </div>
      <q-dialog v-model="showStopBookingDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Stop Booking?</div>
          <p>If the client already returned the vehicle, you can stop this booking. <br>
          Please also rate the client that rented your vehicle and leave a review.</p>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-center">
            <q-rating
              v-model="reviewRating"
              size="2em"
              :max="5"
              color="primary"
            />
          </div>
          <div class="q-py-md">
            <q-input 
              v-model="reviewComment"
              autofocus
              placeholder="(Optional) Enter a comment.."
              @keyup.enter="prompt = false" 
            />
            <small>Only you and Carbnb can see the review you post on your clients.</small>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            color="primary"
            label="Cancel"
            :disabled="isStoppingBooking"
            v-close-popup 
          />
          <q-btn
            color="primary"
            :label="stopButtonLabel"
            @click="stopBooking"
            :loading="isStoppingBooking"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>  
    </div>
</template>

<script setup>
    import { computed, ref, defineEmits } from "vue";
    import { useStore } from "vuex";
    import { useQuasar } from 'quasar'

    const store = useStore();
    const booking = computed(() => store.state.operator.vehicle.booking);
    const $q = useQuasar();

    var showLateNotice = ref(false);
    var showStopBookingDialog = ref(false);

    var reviewComment = ref('');
    var reviewRating = ref(4);
  
    var stopButtonLabel = ref('Stop Booking');
    var isStoppingBooking = ref(false);
  
    const emit = defineEmits(['bookingStop']);
  
    if (booking.value) {
      showLateNotice = booking.value.is_late;
    }

    function stopBooking() {
        stopButtonLabel.value = 'Stopping..';
        isStoppingBooking.value = true;
        store.dispatch("operator/stopBooking", {
          booking_id: booking.value.id,
          rating: reviewRating.value,
          message: reviewComment.value
        }).then((r) => {
        if (r.status == "success") {
          isStoppingBooking.value = false;
          stopButtonLabel.value = 'Stop Booking';
          showStopBookingDialog.value = false;
          $q.dialog({
            title: 'Booking Closed',
            message: 'You have successfully stopped the booking. The booking is now moved to your Closed Bookings tab.'
          }).onOk(() => {
            emit('bookingStop', 1);
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          });
        }
      });
    } 
</script>