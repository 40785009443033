<template>
  <section class="container" style="min-height: 600px">
    <div class="flex justify-between full-width q-py-md">
      <div>
        <span class="text-h6 text-bold text-accent">
          <q-icon name="drive_eta"/> My Vehicles</span>
      </div>
    </div>
    <q-list bordered v-if="forListCars && user.type== 2">
      <ForListCars
        v-for="(car, index) in forListCars"
        :key="index"
        :car="car"
      />
    </q-list>
    <div v-else-if="!forListCars && user.type == 2" class="q-mt-lg text-center">
      <p>You do not have any vehicles. Click Add Vehicle to add a vehicle.</p>
    </div>
    <div v-else>
      <p>You are not yet a verified operator. Please apply <router-link to="/apply-as-operator">here</router-link> and submit your first vehicle application.</p>
    </div>
  </section>
</template>
<script setup>
import ForListCars from "../../components/Profile/ForListCars.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useQuasar } from 'quasar'

const store = useStore();
const user = store.state.user.data;
const forListCars = computed(() => store.state.operator.vehicles);
const $q = useQuasar();

$q.loading.show();
function loadVehicles() {
  store.dispatch("operator/getVehicles").finally(() => {
    $q.loading.hide();
  });
}

loadVehicles();
</script>
<style scoped>
.car-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  flex-direction: column;
}

p > a {
  color: var(--color-primary);
}
p > a:hover {
  text-decoration:underline;
}
</style>
