<template>
  <section>
    <loading-screen-component message="Please wait.." v-if="isLoading"/>
    <div v-else>
      <!-- If the user is currently not signed in, let's encourage them to sign in. -->
      <div class="container q-pa-lg" v-if="state == 1">
        <div>
          <p class="text-bold text-h5 text-accent">Apply as Operator</p>
          <p class="text-bold text-h6 text-grey-7">Please Sign In or Create an Account</p>
          <p class="text-subtitle1">To apply as operator, please sign in or create an account. After this you will be able to submit your vehicle to us and our officer will quickly review it.</p>
        </div> 
        <div>
          <div style="max-width: 350px; margin: auto">
            <q-btn class="btn-primary" label="Sign In" @click="handleSignInClick"/>
            <q-btn class="btn-primary" color="secondary" to="/registration" label="Create an Account"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 q-pa-sm">
            <q-img
              :src="require('@/assets/images/apply-as-operator/carbnb-operator-requirements.jpg')"
              alt="Carbnb Requirements for Applying as Operator"
              :ratio="1"
              class="q-mt-lg"
            />
          </div>
          <div class="col-12 col-md-6 q-pa-sm">
            <q-img
              :src="require('@/assets/images/apply-as-operator/carbnb-aap.jpg')"
              alt="Carbnb Herlene Budol as ambasaddor"
              :ratio="1"
              class="q-mt-lg"
            />
          </div>
        </div>
      </div>
      <!-- If the user is currently signed in and not an operator, lets show the vehicle form. -->
      <div v-else-if="state == 2">
        <VehicleForm v-if="showApplicationForm" :forApplication="true" v-on:success="checkApplication"/>
      </div>
      <!-- If the user has a for review application and not yet seen by officers -->
      <div class="container q-pa-lg" v-else-if="state == 3">
        <div>
          <p class="text-bold text-h5 text-accent">Apply as Operator</p>
          <p class="text-bold text-h6 text-grey-7">Your application has been submitted</p>
          <p class="text-subtitle1">You have successfully submitted your application and it is currently waiting for review. You can frequently check this page and your email for the status of your application.
            If you wish to cancel your application, please click the Withdraw Application.</p>
        </div>
        <div>
          <q-item>
            <q-item-section top thumbnail class="q-ml-none">
              <img :src="application.photo">
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ application.vehicle_name}}</q-item-label>
              <q-item-label>{{ application.plate_number }}</q-item-label>
              <q-item-label caption>{{ application.fuel_type_id }}</q-item-label>
            </q-item-section>
          </q-item>
        </div>
        <div class="q-py-lg">
          <div style="max-width: 400px; margin: auto">
            <q-btn
              class="btn-primary"
              color="primary"
              icon="cancel"
              label="Withdraw Application"
              :loading="isCancelling"
              @click="showWithdrawConfirmation"
            />
          </div>
        </div>
      </div>
      <!-- If the user has a for review application and  an officer is reviewing it already  -->
      <div class="container q-pa-lg" v-else-if="state == 4">
        <div>
          <p class="text-bold text-h5 text-accent">Apply as Operator</p>
          <p class="text-bold text-h6 text-grey-7">Your application is being reviewed</p>
          <p class="text-subtitle1">An onboarding officer is currently reviewing your application and will approve this application soon. Please be advised that they may contact you for additional requirements or any details regarding your vehicle.</p>
        </div>
        <div>
          <q-item>
            <q-item-section top thumbnail class="q-ml-none">
              <img :src="application.photo">
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ application.vehicle_name}}</q-item-label>
              <q-item-label>{{ application.plate_number }}</q-item-label>
              <q-item-label caption>{{ application.fuel_type_id }}</q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import LoadingScreenComponent from '@/components/LoadingScreenComponent';
import { ref, computed, watch, onUnmounted} from 'vue';
import { useStore } from 'vuex';
import VehicleForm from '../components/Car/VehicleForm.vue';
import { notify } from '../utils/notification';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const isLoading = ref(true);
const $q = useQuasar();
const store = useStore();
const isLoggedIn = computed(() => store.state.user.isLoggedIn);
const user = computed(() => store.state.user.data);
const showApplicationForm = ref(false);
const router = useRouter();
const state = ref(1);
const id = ref();
const application = ref();
const isCancelling = ref(false);

initialize();
// Watch for changes in the state computed property
watch(
  isLoggedIn,
  (newValue) => {
    if (newValue == true) {
      // Perform application checking from the server.
      // This will dictate the next state.
      checkApplication();
    }
  }
);

function handleSignInClick() {
  store.commit('user/showSignInModal');
}

const connection = setInterval(() => {
  if (isLoggedIn.value == true && user.value !== null && state.value != 2) {
    checkApplication(false);
  } 
}, 10000);

onUnmounted(() => {
  console.log('The apply as operator connection has been terminated.')
  clearInterval(connection);
});

function initialize() {
  if (isLoggedIn.value == true && user.value !== null) {
    checkApplication();
  } else {
    isLoading.value = false;
    state.value = 1;
  }
}

function checkApplication(showLoading = true) {
  isLoading.value = showLoading;
  store.dispatch("user/checkApplication", user.value.id).then((response) => {
    switch (response.status) {
      case 'can_submit_application':
        showApplicationForm.value = true;
        isLoading.value = false;
        state.value = 2;
        break;
      case 'application_reviewing':
        id.value = response.data.vehicle_application.id;
        application.value = response.data.vehicle_application;
        state.value = 4;
        isLoading.value = false;
        break;
      case 'application_pending':
        id.value = response.data.vehicle_application.id;
        application.value = response.data.vehicle_application;
        state.value = 3;
        isLoading.value = false;
        break;
      case 'already_an_operator':
        notify('info', 'You are already an operator. If you wish to add more vehicles, you may do so by clicking the Add Vehicle button on top your vehicles list.');
        router.push('/my-vehicles');
        break;
    }
  });
}

function showWithdrawConfirmation() {
    $q.dialog({
      title: 'Withdraw Application?',
      message: 'You can only withdraw your application if your application is still pending but you can always resubmit again. Continue withdrawal?',
      cancel: true,
      persistent: true
    }).onOk(() => {
        isCancelling.value = true;
        store.dispatch("vehicle/withdrawApplication", id.value).then(() => {
          notify(
            "positive",
            "You have successfully withdrawn your application."
          );
          initialize();
        }).catch(() => {
          notify(
            "negative",
            "There was an error while trying to cancel your application. Please try again later."
          );
        }).finally(() => {
          isCancelling.value = false;
        });
    }).onOk(() => {
        //
    }).onCancel(() => {
        //
    }).onDismiss(() => {
        //
    })
  }
</script>