// src/store/modules/system.js

const system = {
    namespaced: true,
  
    state: () => ({
      hasFinishedBooting: false,
      isUpdateRequired: false,
      data: [],
      cancellationReasons: null,
      contactNumber: null,
      banners: []
    }),
  
    mutations: {
      finishBoot(state) {
        state.hasFinishedBooting = true;
      },
      startBoot(state) {
        state.hasFinishedBooting = false;
      },
      requireUpdate(state) {
        state.isUpdateRequired = true;
      },
      setCancellationReasons(state, data) {
        state.cancellationReasons = data;
      },
      setContactNumber(state, contactNumber) {
        state.contactNumber = contactNumber;
      },
      setBanners(state, banners) {
        state.banners = banners;
      }
    },

    actions: {
      // You can add async actions here if needed
    },
  };
  
  export default system;