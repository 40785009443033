<template>
  <div class="container q-pa-md">
    <q-card class="q-my-lg">
      <q-card-section>
        <div>
          <span class="text-h6 text-bold text-accent"><q-icon name="library_books"/> My Bookings</span>
          <p>This page shows all the bookings with their pick up date and time.</p>
        </div>
      <div>
        <q-list bordered separator>
          <q-item 
            clickable
            v-ripple v-for="booking in bookings"
            v-bind:key="booking.id"
            :to="'/booking/' + booking.id"
          >
            <q-item-section>
              <p>
                ({{booking.booking_code}}) {{booking.vehicle_brand }} {{ booking.vehicle_name }}
                <br>
                <q-icon name="today"/> <small>{{ booking.pickup_date }}</small>
              </p>
            </q-item-section>
            <q-item-section side top>
              <q-chip
                  size="12px"
                  style="margin: 0"
                  text-color="white"
                  :color="evaluateChipColor(booking)"
                  dense
                  square
                >
                  {{ booking.status }}
                </q-chip>
            </q-item-section>
          </q-item>
        </q-list>
        <div v-if="isEmpty" class="q-mt-lg text-center">
          <q-icon name="library_books" size="28px" class="text-accent q-pa-md"/>
          <p>You do not have any bookings yet. To rent your first vehicle, click Book a Vehicle.</p>
          <q-btn color="primary" class="btn-primary" label="Book a Vehicle" to="/search" icon="add"
            style="max-width: 400px"/>
        </div>
      </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();
const isLoading = ref(true);
const isEmpty = ref(false);
const bookings = computed(() => store.state.booking.bookingsByUser);

// Show loading screen.
$q.loading.show({
  message: 'Updating your bookings..'
});

// Retrieve the bookings of this account.
store.dispatch("booking/fetchBookingsFromCustomer", store.state.user.data.id).then((r) => {
  if (r === 'success') {
    isEmpty.value = false;
    isLoading.value = false;
  } else {
    isEmpty.value = true;
    isLoading.value = false;
  }
}).finally(() => {
  $q.loading.hide();
});

/**
 * Evaluates the chip color to display for the status.
 */
function evaluateChipColor(booking)
{
  if (booking.status === 'WAITING FOR PAYMENT') {
    return 'warning';
  } else if (booking.status === 'ACTIVE') {
    return 'positive';
  } else if (booking.status === 'FOR RELEASE') {
    return 'blue';
  } else if (booking.status === 'COMPLETED') {
    return 'black';
  }
}
</script>
