<template>
    <q-btn-dropdown
      outline
      color="accent"
    >
        <template #label>
        <q-avatar size="32px" class="q-mr-sm">
            <img :src="photo" alt="Avatar" @error="setFallback"/>
        </q-avatar>
        </template>
      <div class="row no-wrap q-pa-md">
        <div class="column">
          <div class="flex justify-between items-center q-mb-md">
            <q-avatar size="36px" class="q-mr-sm">
              <img :src="photo" @error="setFallback">
            </q-avatar>
            <div class="text-h6">{{ name }}</div>
          </div>
          <q-list separator>
            <q-item clickable v-ripple to="/bookings" class="text-subtitle2">
              <q-item-section>My Bookings</q-item-section>
            </q-item>
            <q-item clickable v-ripple class="text-subtitle2" @click="showUserProfileModal">
              <q-item-section>My Profile</q-item-section>
            </q-item>
            <q-item clickable v-ripple v-if="isOperator" to="/my-vehicles"  class="text-subtitle2">
              <q-item-section>My Vehicles</q-item-section>
            </q-item>
            <q-item clickable v-ripple  class="text-subtitle2" to="/change-password">
              <q-item-section>Change Password</q-item-section>
            </q-item>
            <q-item clickable v-ripple  class="text-subtitle2" to="/account-deletion">
              <q-item-section>How to delete account?</q-item-section>
            </q-item>
            <q-item clickable v-ripple @click="logout" v-close-popup  class="text-subtitle2">
              <q-item-section>Sign Out</q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </q-btn-dropdown>  
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const photo = computed(() => store.getters['user/getProfilePhoto']);
const name  = computed(() => truncateString(store.getters['user/getFullName']));
const isOperator = computed(() => truncateString(store.getters['user/isOperator']));

function logout() {
  store.commit("user/logout");
  router.push('/');
}

function setFallback(e) {
  e.target.src = '/default_profile.jpg';
}

function truncateString(str) {
  if (str.length > 20) {
    return str.substring(0, 20) + '...';
  }

  return str;
}

function showUserProfileModal() {
  store.commit('user/showProfileModal');
}
</script>