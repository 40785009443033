import axios from "axios";

const affiliate = {
    state: () => ({
      name: null,
      code: null,
      discount: null,
      informed: false,
      is_available: false
    }),
    mutations: {
      setAffiliate(state, payload) {
        if (payload) {
          state.name = payload.name;
          state.code = payload.code;
          state.discount = payload.discount;
          state.is_available = payload.is_available;
          if (state.is_available == true) {
            state.informed = false;
          }
        }
      },
      setInformed(state, value) {
        state.informed = value;
      },
      clear(state) {
        state.name = null;
        state.code = null;
        state.discount = null;
        state.is_available = false;
        state.informed = false;
      }
    },
    actions: {
      transmit({ commit }, code) {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("id");
        
        var payload = {
          code: code,
          token: token,
          userId: userId
        };

        return new Promise((resolve, reject) => {
          axios({
            url: `/api/v1/aid-validate`,
            method: "POST",
            data: payload
          }).then((response) => {
            if (response.data.status == 'success') {
              const affiliate = response.data.data;
              commit("setAffiliate", affiliate);
            }

            return resolve({status: "success", affiliate: response.data.data});
          }).catch((err) => {
            console.error(err);

            return reject();
          });
        });
      },
    },
    namespaced: true,
};
  
export default affiliate;