<template>
  <section>
    <div class="column text-center">
      <span class="text-h6"> <q-icon name="check_circle" class="text-positive" size="sm"/> You have successfully paid <span class="text-positive">{{ invoice.reservation_fee }}</span> of {{ term }}.</span>
      <span class="text-subtitle1 text-grey-6">
        We will send you a confirmation on your registered email.
      </span>
    </div>
    <div class="q-mt-md text-center">
      <q-btn rounded color="primary" size="lg" label="Confirm" @click="close"/>
    </div>
  </section>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, defineEmits } from 'vue';

const store = useStore();
const invoice = ref(store.state.payment.data.invoice);
const term = ref('reservation fee');
const emits = defineEmits(['close']);

if (invoice.value.type != 'Booking') {
  term.value = 'extension fee';
}

function close() {
  emits('close', true);
}
</script>