import { useStore } from "vuex";
import { getDateToday } from "@/core/datehelper";
import { notify } from "@/utils/notification";
import axios from "axios";

/**
 * A boot script that performs all necessary setups and cleanups before the actual
 * app starts.
 */
export function boot() {
    const store = useStore();
    store.commit('system/startBoot');

    // Clean up the required version keys and links on app create.
    localStorage.removeItem('required_version');
    localStorage.removeItem('app_store');
    localStorage.removeItem('play_store');

    // Clean up the vuex persist if there is since we do not use
    // the persisted state anymore.
    localStorage.removeItem('vuex');

    // Set up the Axios Request interceptor
    axios.interceptors.request.use(
        function (config) {
          const token = localStorage.getItem("token");
          const user_id = localStorage.getItem("id");
          const Auth = `Bearer ${user_id} ${token}`;
          config.headers.Authorization = Auth;
  
          // Include the platform on each request.
          config.headers['X-AppPlatform'] = process.env.VUE_APP_BUILD_TARGET;
  
          // Include the version on each request.
          if (config.headers['X-AppPlatform'] === 'mobile') {
            config.headers['X-AppVer'] = btoa(process.env.VUE_APP_VERSION);
          }
  
          return config;
        },
        function (error) {
          console.error(
            "The Axios HTTP request has failed. Please see the logs for more details."
          );
          console.error(error);
          return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) { return response; },
        function (error) {
          if (error.response.status == 400) {
            if (error.response.data.status == 'update_required') {
              console.error('You are using an outdated version of the app. The system requires you to update to use its services.');
              localStorage.setItem("required_version", error.response.data.data.required_version);
              localStorage.setItem("app_store", error.response.data.data.app_store);
              localStorage.setItem("play_store", error.response.data.data.play_store);
              store.commit('system/requireUpdate');
            }
          }
          if (error.response.status == 403) {
            store.commit('user/logout');
            notify("warning", "Your account has been signed out from the system. Please sign in again.");
          }
          if (
            error.response.status == 500
          ) {
            notify("red", "An error has occurred. Please try again later.");
          }
          return Promise.reject(error);
        }
    );

    // Initialize the pick up date as today + 4 hours.
    store.commit('search/setPickupDate', getDateToday(4));

    axios({
      method: 'GET',
      url: '/api/v1/clients/init'
    }).then((response) => {
      store.commit('search/setYards', response.data.data.yards);
      store.commit('search/setBlockedDates', response.data.data.blocked_dates);
      store.commit('system/setCancellationReasons', response.data.data.cancellation_categories);
      store.commit('system/setContactNumber', response.data.data.contact_number);
      store.commit('system/setBanners', response.data.data.banners);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setTimeout(() => {
        store.commit('system/finishBoot');
      }, 3000);
    });
}
