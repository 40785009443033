import script from "./Bookings.vue?vue&type=script&setup=true&lang=js"
export * from "./Bookings.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QIcon,QList,QItem,QItemSection,QChip,QBtn});qInstall(script, 'directives', {Ripple});
