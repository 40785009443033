<template>
    <q-dialog v-model="showModal">
        <q-card style="min-width: 350px">
            <q-card-section class="row items-center q-pb-none">
            <div class="text-h6 text-accent text-bold"><q-icon name="discount"></q-icon> Beep, beep!</div>
            <q-space />
            <q-btn icon="close" flat round dense @click="emit('close')" />
            </q-card-section>
          <q-card-section>
            For using the link of <b>{{ name }}</b>. You are eligible to claim <strong>{{ labelDiscountValue }} </strong> off on your next booking by using the code <strong>{{ code }}</strong><br><br>Sign In or Register now to claim your discount.
          </q-card-section>
        </q-card>
    </q-dialog>
  </template>
  
  <script setup>
  import { useStore } from "vuex";
  import { defineProps, computed, defineEmits } from "vue";
  
  const props = defineProps({
    show: {
        type: Boolean,
        value: false
    }
  });

  const emit = defineEmits(['close']);

  const store = useStore();
  const name = computed(() => store.state.affiliate.name);
  const code = computed(() => store.state.affiliate.code);
  const showModal = computed(() => props.show);
  const labelDiscountValue = computed(() => store.state.affiliate.discount);
</script>