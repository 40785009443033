<template>
  <section>
    <div class="container">
      <div class="row justify-center q-gutter-xl">
          <q-card
            v-for="(testimonial, ind) in testimonials"
            :key="ind"
            class="col-12 col-md-4 col-sm-12 my-card"
            flat
            bordered
            dark
          >
            <q-icon
              name="fas fa-quote-right"
              class="text-accent absolute"
              size="10.4em"
              style="bottom: -70px; right: 0px; transform: translateY(-50%); opacity: 0.4;"
            />
            <q-card-section horizontal>
              <q-img class="col-6 card-image" :src="testimonial.thumbnail" />
              <q-card-section class="q-py-md">
                <b class="text-light bg-accent q-pa-xs q-my-xs" style="line-height: 3.0;">{{
                  testimonial.author
                }}</b>
                <small class="q-py-xs" v-html="testimonial.content"></small>
              </q-card-section>
            </q-card-section>
            <q-icon
              name="fas fa-quote-left"
              class="text-light rounded shadow-lg absolute q-pa-xs"
              size="5.4em"
              style="top: 20px; left: -20px; transform: translateY(-50%)"
            />
          </q-card>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const testimonials = ref([]);

function fetchTestimonials() {
  store
    .dispatch("posts/fetchTestimonials")
    .then((response) => {
      testimonials.value = response.models.testimonials;
    })
    .catch(() => {})
    .finally(() => {});
}

fetchTestimonials();
</script>
<style scoped>
.btn-actions {
  min-width: 250px;
  border-radius: 15px;
  height: 50px;
}
.card-content {
  min-height: 360px;
  max-height: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-image {
  min-height: 360px;
  max-height: 360px;
}
.my-card {
  max-height: 360px;
  min-height: 360px;
  overflow: hidden;
}
</style>
