<template>
  <section>
    <div class="q-px-xs">
        <div class="column q-py-md">
            <span class="text-h5 q-pa-xs text-grey-6">
                <q-icon name="list_alt"/> Your Booking Details
            </span>
            <span class="text-subtitle1">Here are your current booking details. Please make sure that everything is correct before you settle the reservation fee.</span>
        </div>
        <div class="q-pa-xs" style="min-width: 350px">
            <div class="row">
                <div class="col-12">
                    <booking-details-component :booking="booking"/>
                </div>
                <div class="col-12 text-center" v-if="invoice">
                    <div class="q-py-lg">
                        <q-btn rounded color="primary" size="lg" @click="proceed">
                            <template v-slot>
                                <div class="column">
                                    <span>{{ buttonLabel }}</span>
                                    <span class="text-caption">VAT Inclusive</span>
                                </div>
                            </template>
                        </q-btn>
                    </div>
                    <p class="text-caption">
                        You are only paying for the reservation fee so that the vehicle will be reserved to you.
                        Upon pick up of the vehicle, you will pay the final amount of <b>{{ invoice.operator_fee }}</b> to be guided by our PDI officers or the operator.
                    </p>
                </div>
                <div class="col-12 q-py-xl" v-else>
                    <q-banner class="bg-positive text-white">
                        <div class="flex items-center justify-center">
                            <q-icon name="check_circle" size="xl" class="q-mx-md gt-sm"></q-icon>
                            <div class="column">
                                <span class="text-h5">All Paid</span>
                                <span class="text-subtitle1">This booking has no pending payments for reservation or extension.</span>
                            </div>
                        </div>
                    </q-banner>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script setup>
import BookingDetailsComponent from '@/components/Booking/BookingDetailsComponent'
import { ref, defineEmits } from 'vue';
import { useStore } from 'vuex';

const emits = defineEmits(['proceed'])
const store = useStore();
const booking = ref(store.state.payment.data.booking);
const invoice = ref(store.state.payment.data.invoice);

const buttonLabel = ref();
const invoiceNumber = ref();

if (invoice.value !== null) {
    buttonLabel.value = 'Pay for ' + invoice.value.reservation_fee;
    invoiceNumber.value = invoice.value.invoice_number;
}

function proceed() {
    emits('proceed', invoiceNumber.value);
}
</script>