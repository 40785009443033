import { createRouter } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Registration from '../views/Registration.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Policy from '../views/Policy.vue'
import MyVehicles from '../views/Operator/MyVehicles.vue'
import MyVehicle from '../views/Operator/MyVehicle.vue'
import Bookings from '../views/Bookings.vue'
import Booking from '../views/Booking.vue'
import Vehicle from '../views/Vehicle.vue'
import Search from '../views/Search.vue'
import CreateVehicle from '../views/Operator/AddVehicle.vue'
import ConfirmPayment from '../views/ConfirmPayment.vue'
import AboutUs from '../views/AboutUs.vue'
import Contact from '../views/Contact.vue'
import ErrorPayment from '../views/404.vue'
import NotFound from '../views/NotFound.vue'
import ApplyAsOperator from '../views/ApplyAsOperator'
import AccountDeletion from '../views/AccountDeletion.vue'
import Vouchers from '../views/Vouchers.vue'
import FeedbackForm from '../views/FeedbackForm.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Payment from '../views/Payment.vue'
import Verification from '../views/Verification.vue'

//import MyWallet from '../views/MyWallet.vue'

// Check the router mode to use depending on the build.
const routerMode = process.env.VUE_APP_BUILD_TARGET === 'website' ? 'history' : 'hash';

// Import the appropriate router mode dynamically.
const routerHistory = routerMode === 'history'
  ? require('vue-router').createWebHistory(process.env.BASE_URL)
  : require('vue-router').createWebHashHistory();

function requireLoggedIn(to, from, next) {
  if (store.state.user.isLoggedIn) {
    next();
  } 
  else {
    next('/');
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/booking/:id/payment',
    name: 'Payment',
    component: Payment,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/our-policy',
    name: 'Policy',
    component: Policy
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/my-vehicles',
    name: 'MyVehicles',
    component: MyVehicles,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/my-vehicle/:id',
    name: 'MyVehicle',
    component: MyVehicle,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: Bookings,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/booking/:id',
    name: 'Booking',
    component: Booking,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/ew-confirm',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/vehicle/:id',
    name: 'Vehicle',
    component: Vehicle
  },
  {
    path: '/vehicle/create',
    name: 'CreateVehicle',
    component: CreateVehicle,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: Vouchers,
    beforeEnter: requireLoggedIn
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/contact',
    name: 'Contactus',
    component: Contact
  },
  {
    path: '/account-deletion',
    name: 'AccountDeletion',
    component: AccountDeletion
  },
  {
    path: '/apply-as-operator',
    name: 'ApplyAsOperator',
    component: ApplyAsOperator
  },
  {
    path: '/error',
    name: 'ErrorPayment',
    component: ErrorPayment,
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/feedback-form',
    name: 'FeedbackForm',
    component: FeedbackForm
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  // {
  //   path: '/wallet',
  //   name: 'MyWallet',
  //   component: MyWallet,
  //   beforeEnter: requireLoggedIn,
  // },
]

const router = createRouter({
  history: routerHistory,
  mode: routerMode,
  routes,
  scrollBehavior () {
    return { top:0 }
  }
})

router.beforeEach((to) => {
  // Hide Footer on Search Page
  const currentRoute = to.name;
  store.state.displayFooter = currentRoute == 'Search'
  || currentRoute == 'Messages'
  || currentRoute == 'Registration'
  || currentRoute == 'ApplyAsOperator'
  ? false : true
})

export default router