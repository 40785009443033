<template>
  <section class="registration q-pa-sm">
    <q-card bordered>
      <q-card-section>
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="privacy_policy">
            <div class="text-h6 text-bold text-accent">Privacy Policy</div>
            Before creating an account for Carbnb, please read our Privacy Policy.
            <q-separator class="q-my-md"/>
            <q-scroll-area style="height: 300px; max-width: 800px;">
              <privacy-policy-component/>
            </q-scroll-area>
            <q-separator class="q-my-md"/>
            <div class="row items-center">
              <div class="col-12 col-md-8 q-pa-xs">
                <q-checkbox v-model="iAgree" label="I have read and understand the Privacy Policy of Carbnb as stated above." />
              </div>
              <div class="col-12 col-md-4 q-pa-xs">
                <q-btn class="btn-primary" label="Next"
                  :disabled="!iAgree" icon-right="arrow_right" @click="tab = 'registration_form'"/>
              </div>
          </div>
          </q-tab-panel>

          <q-tab-panel name="registration_form">
            <div class="text-h6 text-bold text-accent">Create Account</div>
            <div class="q-pt-none">
              <q-input
                  v-model="firstName.value"
                  filled
                  class="q-py-md"
                  placeholder="First Name"
                  :error="firstName.error !== null"
                  :error-message="firstName.error"
                  :loading="isRegistering"
              />
              <q-input
                  v-model="lastName.value"
                  filled
                  class="q-py-md"
                  placeholder="Last Name"
                  :error="lastName.error !== null"
                  :error-message="lastName.error"
                  :loading="isRegistering"
              />
              <q-input
                  v-model="email.value"
                  filled
                  type="email"
                  class="q-py-md"
                  placeholder="Email"
                  :error="email.error !== null"
                  :error-message="email.error"
                  :loading="isRegistering"
              />
              <q-input
                  v-model="mobileNumber.value"
                  filled
                  prefix="+63"
                  maxlength="10"
                  class="q-py-md"
                  placeholder="9XXXXXXXXX"
                  :error="mobileNumber.error !== null"
                  :error-message="mobileNumber.error"
                  :loading="isRegistering"
              />
            </div>
            <q-separator class="q-my-md"/>
            <div class="row items-center">
              <div class="col-12 col-md-6 q-pa-xs">
                <q-btn class="btn-primary" color="secondary" :disabled="isRegistering" label="Back" icon="arrow_left" @click="tab = 'privacy_policy'"/>
              </div>
              <div class="col-12 col-md-6 q-pa-xs">
                <q-btn class="btn-primary" label="Next"
                  :loading="isRegistering" icon-right="arrow_right" @click="register"/>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="verification">
            <div v-if="!isRetrievingVerification">
              <div class="text-h6 text-bold text-accent">Email Verification</div>
              We have sent a code to your registered email address. Please enter it here:
              <q-input
                  v-model="code.value"
                  filled
                  class="q-py-md"
                  placeholder="Enter Verification Code"
                  :error="code.error !== null"
                  :error-message="code.error"
                  :loading="isVerifying"
              />
              <div class="row items-center justify-center">
                <div class="col-12 col-md-6 q-pa-xs">
                  <q-btn class="btn-primary" label="Verify"
                    :loading="isVerifying" icon-right="arrow_right" @click="verify(true)"/>
                </div>
              </div>
            </div>
            <LoadingScreenComponent message="Please wait.." v-else/>
          </q-tab-panel>

          <q-tab-panel name="password">
            <div class="text-h6 text-bold text-accent">Enter a Password</div>
            Please enter a password for your account. We recommend a password that is at least 8 characters long.
            <q-input
              v-model="password.value"
              type="password"
              filled
              class="cb-input q-py-md"
              placeholder="New Password"
              :loading="isSaving"
              :error="password.error != null"
              :error-message="password.error"
            />
            <q-input
              v-model="repeatPassword.value"
              type="password"
              filled
              class="cb-input q-py-md"
              placeholder="Repeat Password"
              :loading="isSaving"
              :error="repeatPassword.value != password.value"
              error-message="The repeat password does not match with the password above."
            />
            <div class="row items-center justify-center">
              <div class="col-12 col-md-6 q-pa-xs">
                <q-btn class="btn-primary" label="Save Password"
                  :loading="isSaving" icon-right="arrow_right" @click="verify(false)"/>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="success">
            <div class="text-h6 text-bold text-accent">Success</div>
            You successfully registered an account for Carbnb.
            <div class="row items-center justify-center q-mt-xl">
              <div class="col-12 col-md-6 q-pa-xs">
                <q-btn class="btn-primary" label="Confirm" to="/"/>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
    </q-card>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import LoadingScreenComponent from '@/components/LoadingScreenComponent';
import axios from 'axios';
import { useStore } from 'vuex';
import { notify } from "../utils/notification";
import { useRoute, useRouter } from 'vue-router';
import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent.vue';
const tab = ref('privacy_policy');
const iAgree = ref(false);
const isRegistering = ref(false);
const isRetrievingVerification = ref(false);
const isVerifying = ref(false);
const isSaving = ref(false);
const store = useStore();
const route = useRoute();
const router = useRouter();

const firstName = ref({
  value: null,
  error: null
});
const lastName = ref({
  value: null,
  error: null
});
const email = ref({
  value: null,
  error: null
});
const mobileNumber = ref({
  value: null,
  error: null
});
const code = ref({
  value: null,
  error: null
});
const password = ref({
  value: null,
  error: null
});
const repeatPassword = ref({
  value: null,
  error: null
});

if (route.query.email) {
  handleAppendedEmailForVerification(route.query.email);
}

function handleAppendedEmailForVerification(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(route.query.email)) {
    isRetrievingVerification.value = true;
    store.commit('setForVerification', email);
    tab.value = 'verification';
    store.dispatch('resendVerification', { email: email }).then((response) => {
      switch (response.data.status) {
        case 'already_verified':
          notify("positive", "This email address is already verified and in-use. If you own this email address, please try signing in.");
          router.push('/');
          break;
        case 'interval_required':
          notify("secondary", response.data.data.message);
          break;
        case 'verification_email_sent':
          notify("positive", "We have sent a 6-digit code to your email. Please check your inbox and enter it here.");
          break;
      }
    }).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            tab.value = 'privacy_policy';
            notify("negative", "The email address that you entered was not found. If you own this email address, please create an account instead.");
            break;
          default:
            notify("negative", "There has been an error while trying to send verification to that email address. Please try again later.");
            break;
        }
      }
    }).finally(() => {
      isRetrievingVerification.value = false;
    })
  } else {
    console.error('The current email from the route is not a valid email address.');
  }
}
/**
 * Function that sends the registration form to the server.
 */
function register() {
 isRegistering.value = true;

 firstName.value.error = null;
 lastName.value.error = null;
 mobileNumber.value.error = null;
 email.value.error = null;

 var payload = {
  first_name: firstName.value.value,
  last_name: lastName.value.value,
  email: email.value.value,
  mobile_number: mobileNumber.value.value
 };

 if (store.state.affiliate.code !== null) {
  payload.code = store.state.affiliate.code;
 }

 axios({
    url: "/api/v1/users",
    method: "POST",
    data: payload,
  }).then((response) => {
    if (response.data.status == 'success') {
      store.commit('setForVerification', payload.email);
      tab.value = 'verification';
    }
  }).catch((err) => {
    if (err.response) {
      if (err.response.status == 400) {
        const errors = err.response.data.data.errors;
        errors.forEach(error => {
          if (error.key == 'first_name') {
            firstName.value.error = error.message;
          }
          if (error.key == 'last_name') {
            lastName.value.error = error.message;
          }
          if (error.key == 'email') {
            email.value.error = error.message;
          }
          if (error.key == 'mobile_number') {
            mobileNumber.value.error = error.message;
          }
        });
      } else {
        notify('red', 'Sorry but we experienced some error. Please try again.');
      }
    } else {
      notify('red', 'Sorry but we experienced some technical error. We are working on fixing the issue.');
    }
  }).finally(() => {
    isRegistering.value = false;
  });
}

/**
 * Function that sends a verification for the currently active
 * email address for verification.
 */
function verify(isPreVerify = false) {
  if (!isPreVerify) {
    if (password.value.value != repeatPassword.value.value) {
      return;
    }
  }

  isVerifying.value = true;

  var payload = {
    email: store.state.email,
    code: code.value.value,
    password: password.value.value
  };

  if (isPreVerify) {
    payload.mode = 'pre-verify';
  }
  axios({
    url: `/api/v1/users/verify`,
    method: "POST",
    data: payload,
  }).then((response) => {
    if (isPreVerify) {
      if (response.data.status == 'pre_verify_ok') {
        tab.value = 'password';
      }
    } else {
      tab.value = 'success';
    }
  }).catch((err) => {
    if (err.response) {
      if (err.response.status == 400) {
        const errors = err.response.data.data.errors;
        errors.forEach(error => {
          if (isPreVerify) {
            if (error.key == 'code') {
              code.value.error = error.message;
            } else {
              notify('warning', error.message);
            }
          } else {
            if (error.key == 'password') {
              password.value.error = error.message;
            } else {
              notify('warning', error.message);
            }
          }
        });
      } else {
        notify('red', 'Sorry but we experienced some error. Please try again.');
      }
    } else {
      notify('red', 'Sorry but we experienced some technical error. We are working on fixing the issue.');
    }
  }).finally(() => {
    isVerifying.value = false;
  })
}

</script>
<style scoped>
.registration {
  max-width: 800px;
  margin: auto
}
</style>
