<template>
  <section>
    <div class="row">
        <div class="col-12 q-pa-sm">
            <q-input 
                filled 
                v-model="cardDetails.number" 
                label="Enter Card Number" 
                mask="################"
                fill-mask=""
                :error="inputFields.cardNumber.hasError"
                :error-message="inputFields.cardNumber.errorText"
                :disable="!allowInputs" 
            />
        </div>
        <div class="col-md-4 col-6 q-pa-sm">
            <q-input 
                filled 
                v-model="cardDetails.expMonth" 
                label="Expiration Month" 
                mask="##"
                fill-mask=" "
                :error="inputFields.expMonth.hasError"
                :error-message="inputFields.expMonth.errorText"
                :disable="!allowInputs"
            />

        </div>
        <div class="col-md-4 col-6 q-pa-sm">
            <q-input 
                filled 
                v-model="cardDetails.expYear" 
                label="Expiration Year" 
                mask="##"
                fill-mask=" "
                :error="inputFields.expYear.hasError"
                :error-message="inputFields.expYear.errorText"
                :disable="!allowInputs" 
            />
        </div>
        <div class="col-md-4 col-12 q-pa-sm">
            <q-input 
                filled 
                v-model="cardDetails.securityCode" 
                label="Security Code (CVC)"
                mask="###"
                fill-mask=" "
                :error="inputFields.cvc.hasError"
                :error-message="inputFields.cvc.errorText"
                :disable="!allowInputs"                     
            />
        </div>
        <div class="col-12 column items-center justify-center q-py-md">
            <span class="text-subtitle2">You are paying for <span class="text-positive">{{ reservationFee }}</span>.</span>
            <img src="/payment-card.png" style="width: 150px">
        </div>
    </div>
    <div class="flex items-center justify-center q-mt-md">
        <q-btn rounded color="secondary"
            class="q-ma-sm" size="lg" :disabled="!allowInputs" label="Cancel" style="width: 250px"
            @click="cancel"
            />
        <q-btn rounded color="primary" size="lg" icon-right="payment" class="q-ma-sm"
            label="Submit Card" @click="submit" :loading="!allowInputs" style="width: 250px"/>
    </div>
  </section>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useStore } from 'vuex';

const allowInputs = ref(true);
const emits = defineEmits(['submit', 'cancel']);
const store = useStore();
const reservationFee = ref(store.state.payment.data.invoice.reservation_fee);

const cardDetails = ref({
    number: "",
    expMonth: null,
    expYear: null,
    securityCode: null
});

const inputFields = ref({
    cardNumber: {
        pointer: 'details.card_number',
        attribute: 'Card Number',
        hasError: false,
        errorText: ''
    },
    expMonth: {
        pointer: 'details.exp_month',
        attribute: 'Expiration Month',
        hasError: false,
        errorText: ''
    },
    expYear: {
        pointer: 'details.exp_year',
        attribute: 'Expiration Year',
        hasError: false,
        errorText: ''
    },
    cvc: {
        pointer: 'details.cvc',
        attribute: 'CVC',
        hasError: false,
        errorText: ''
    }                                                               
});

resetValidations();

function cancel() {
    emits('cancel', true);
}

function handleErrors(errors) {
    errors.forEach((error) => {
        switch (error.source.attribute) {
            case 'card_number':
                inputFields.value.cardNumber.errorText = error.detail.replace(
                    error.source.pointer,
                    inputFields.value.cardNumber.attribute
                );
                inputFields.value.cardNumber.hasError = true;
            break;
            case 'exp_month':
                inputFields.value.expMonth.errorText = error.detail.replace(
                    error.source.pointer,
                    inputFields.value.expMonth.attribute
                );
                inputFields.value.expMonth.hasError = true;
            break;
            case 'exp_year':
                inputFields.value.expYear.errorText = error.detail.replace(
                    error.source.pointer,
                    inputFields.value.expYear.attribute
                );
                inputFields.value.expYear.hasError = true;
            break;
            case 'cvc':
                inputFields.value.cvc.errorText = error.detail.replace(
                    error.source.pointer,
                    inputFields.value.cvc.attribute
                );
                inputFields.value.cvc.hasError = true;
            break;                                                                                     
        }
    })
}

function resetValidations() {
    inputFields.value.cardNumber.hasError = false;
    inputFields.value.cardNumber.errorText = '';

    inputFields.value.expMonth.hasError = false;
    inputFields.value.expMonth.errorText = '';      

    inputFields.value.expYear.hasError = false;
    inputFields.value.expYear.errorText = '';    

    inputFields.value.cvc.hasError = false;
    inputFields.value.cvc.errorText = '';    
}

function submit() {
    resetValidations();
    allowInputs.value = false;
    store.dispatch('payment/submitCard', cardDetails.value).then((response) => {
        if (response.status == 'card_submitted') {
            emits('submit', response.paymentMethodId);
        }
    }).catch((err) => {
        if (err.response) {
            if (err.response.status == 400) {
                handleErrors(err.response.data.errors);
            }
        }
    }).finally(() => {
        setTimeout(() => {
            allowInputs.value = true;
        }, 3000);
    })
}
</script>