import script from "./DatePickerComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./DatePickerComponent.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn,QPopupProxy,QDate,QTime});
