import axios from "axios";

const posts = {
  state: () => ({
    data: null
  }),

  actions: {
    fetchNews({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/admin-posts/news`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              commit("setNews", response.data.data);
              return resolve({
                status: "success",
                models: response.data.data
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchArticles({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/admin-posts/articles`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              commit("setArticles", response.data.data);
              return resolve({
                status: "success",
                models: response.data.data
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchTestimonials({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/admin-posts/testimonials`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              commit("setTestimonials", response.data.data);
              return resolve({
                status: "success",
                models: response.data.data
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
  },

  mutations: {
    setNews(state, payload) {
      state.data = payload;
    },
    setArticles(state, payload) {
      state.data = payload;
    },
    setTestimonials(state, payload) {
      state.data = payload;
    },
    logout(state) {
      state.data = null;
    }
  },
  namespaced: true,
};

export default posts;
