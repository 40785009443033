<template>
    <q-input 
        filled 
        v-model="displayDate"
        :disable="props.disable"
        readonly
        :error="props.error != null"
        :error-message="props.error"
    >
        <template v-slot:prepend>
            <q-btn
                label="Click to Set"
                icon="event"
                flat
            >
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <div class="row q-pa-xs bg-white" style="max-width: 300px;">
                        <div class="col-12 q-py-xs">
                            <q-date v-model="date" @update:model-value="onDateTimeUpdate" mask="YYYY-MM-DD"></q-date>
                            <q-input 
                                filled 
                                v-model="displayTime"
                                :disable="props.disable"
                                style="max-width: 290px"
                                readonly
                                @update:model-value="onDateTimeUpdate"
                            >
                                <template v-slot:prepend>
                                    <q-btn
                                        label="Set Time"
                                        icon="schedule"
                                        flat
                                    >
                                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                            <q-time v-model="time" @update:model-value="onDateTimeUpdate"></q-time> 
                                        </q-popup-proxy>
                                    </q-btn>
                                </template>
                            </q-input>                          
                        </div>
                    </div>
                </q-popup-proxy>
            </q-btn>
        </template>
    </q-input>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { convert24HourToAMPM, convertDateTimeToHumanReadable} from '../core/datehelper';

const date = ref();
const time = ref();

const displayDate = ref();
const displayTime = ref();

const props = defineProps({
    disable: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: null
    }
})

const emits = defineEmits(['select']);

function onDateTimeUpdate() {
    if (time.value) {
        displayTime.value = convert24HourToAMPM(time.value);
    }
    if (date.value && time.value) {
        displayDate.value = convertDateTimeToHumanReadable(date.value + ' ' + time.value);
        emits('select', date.value + ' ' + time.value);
    }
}
</script>