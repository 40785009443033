<template>
    <section>
        <div class="row">
            <div class="col-12">
                <div class="column">
                    <span class="text-h6">Select Pickup Date</span>
                    <p>Please select the date when you will pick up the vehicle.</p>
                    <q-date flat mask="YYYY-MM-DD HH:mm"
                        v-model="selectedPickupDate"
                        class="date-setter"
                        @update:model-value="updateSelectedDate"
                        :options="computeDateOptions"
                    />
                </div>
                <div class="column">
                    <span class="text-h6">Select Pickup Time</span>
                    <p>Please select the time when you will pick up the vehicle.</p>
                    <q-input filled v-model="formattedPickupTime" readonly>
                        <template v-slot:append>
                            <q-btn flat icon="access_time" class="cursor-pointer" label="Change Time">
                                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-time
                                    v-model="selectedPickupTime"
                                    @update:model-value="updateSelectedTime"
                                    :format24h="false"
                                    :options="computeHourOptions"
                                >
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                    </div>
                                </q-time>
                                </q-popup-proxy>
                            </q-btn>
                        </template>
                    </q-input>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useStore } from "vuex";
import { convert24HourToAMPM, getDateToday, getDateOnly, generate24HourTimesFrom, getTimeOnly } from '../../core/datehelper';

const store = useStore();
const emits = defineEmits(['update']);
const selectedPickupDate = ref(store.state.search.pickupDate.value);
const selectedPickupTime = ref(getTimeOnly(store.state.search.pickupDate.value));
const formattedPickupTime = ref(convert24HourToAMPM(selectedPickupTime.value));
const blockedDates = ref(store.state.search.references.blockedDates);
const rawFirstDate = getDateToday(4);
const rawEndDate = getDateToday(2160);

let allowedDates = [];
const startingHour = rawFirstDate.split(' ')[1].split(':')[0];
let allowedTimes = generate24HourTimesFrom(startingHour);

for (let date = new Date(rawFirstDate); date <= new Date(rawEndDate); date.setDate(date.getDate() + 1)) {
    const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '/');

    if (!blockedDates.value.includes(formattedDate)) {
        allowedDates.push(formattedDate);
    }
}

updateSelectedDate();

/**
 * Function that handles selected date update by the user.
 */
function updateSelectedDate() {
    if (getDateOnly(selectedPickupDate.value) != getDateOnly(rawFirstDate)) {
        allowedTimes = generate24HourTimesFrom(0);
    } else {
        const startingHour = rawFirstDate.split(' ')[1].split(':')[0];
        allowedTimes = generate24HourTimesFrom(startingHour);
    }
    var value = getDateOnly(selectedPickupDate.value) + ' ' + selectedPickupTime.value;
    store.commit('search/setPickupDate', value);
    emits('update', value);
}

/**
 * Function that handles selected time update by the user.
 *
 * @param time
 */
function updateSelectedTime(time) {
    var value = getDateOnly(selectedPickupDate.value) + ' ' + selectedPickupTime.value;
    store.commit('search/setPickupDate', value);
    formattedPickupTime.value = convert24HourToAMPM(time);
    emits('update', value);
}

/**
 * Function that determines the dates that the user is allowed to select
 * for their pick up and return date.
 *
 * @param date
 */
function computeDateOptions(date) {
    const formattedDate = date.split('T')[0];

    return allowedDates.includes(formattedDate);
}

/**
 * Function that determines the hours that the user is allowed to select
 * for their pick up and return date.
 * 
 * @param time
 */
function computeHourOptions(time) {
    return allowedTimes.includes(time);
}
</script>
<style scoped>
    .date-setter {
        width: 100%;
        box-sizing: border-box;
    }

    /* Styles for extra small devices */
    @media (max-width: 599px) {
        .date-setter {
            width: 100%;
        }
    }

    /* Styles for small devices */
    @media (min-width: 600px) and (max-width: 1023px) {
        .date-setter {
            width: 100%;
        }
    }

    /* Styles for medium devices */
    @media (min-width: 1024px) and (max-width: 1439px) {
        .date-setter {
            width: 100%;
            max-width: 500px;
        }
    }

    /* Styles for large devices */
    @media (min-width: 1440px) {
        .date-setter {
            width: 100%;
            max-width: 500px;
        }
    }

    /* Styles for extra large devices */
    @media (min-width: 1920px) {
        .date-setter {
            width: 100%;
            max-width: 650px;
        }
    }
</style>