<template>
  <section>
    <div class="q-pa-md q-mb-md row justify-start content-stretch q-gutter-md">
      <q-card flat>
        <q-card-section>
          <h4 class="text-h5 text-bold text-accent">
            <q-icon name="phone" /> Contact Us
          </h4>
          <div class="text-grey-7">
            <span class="text-h6">We would love to hear from you!</span>
            <p>Send us a message and we’ll contact you back.</p>
            <p>
              If you would like to become an operator for Carbnb, please
              indicate it on the message box and we would be glad to guide you
              through the process.
            </p>
          </div>
        </q-card-section>
        <q-card-section>
          <q-form @submit="sendInquiry">
            <div class="row q-gutter-sm q-mb-sm">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <q-input
                  v-model="firstname"
                  filled
                  placeholder="First Name"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Please enter your first name.',
                  ]"
                />
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <q-input
                  v-model="lastname"
                  filled
                  placeholder="Last Name"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) || 'Please enter your last name.',
                  ]"
                />
              </div>
            </div>
            <div class="row q-gutter-sm q-mb-sm">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <q-input
                  v-model="email"
                  type="email"
                  filled
                  placeholder="Email Address"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Please enter your email address',
                  ]"
                />
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <q-input
                  v-model="phone"
                  type="number"
                  filled
                  placeholder="9XXXXXXXXX"
                  prefix="+63"
                  maxlength="10"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Please enter your phone number',
                  ]"
                />
              </div>
            </div>
            <div class="row q-mb-sm q-px-none items-stretch">
              <div class="col-md-8 col-sm-12 col-xs-12">
                <q-input
                  v-model="message"
                  type="textarea"
                  filled
                  autogrow
                  placeholder="Message"
                  :error="generalError != ''"
                  :error-message="generalError"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Please enter your message or concern.',
                  ]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <q-btn
                  class="btn-primary"
                  icon-right="send"
                  :loading="isLoading"
                  type="submit"
                  label="Send"
                />
              </div>
            </div>
          </q-form>
        </q-card-section>
        <q-card-section class="text-grey-7">
          <br /><b
            >You can also contact us directly through this mobile number and
            email.</b
          ><br /><br />
          <q-icon name="phone" class="q-mr-sm"></q-icon
          ><a class="text-grey-7" :href="`tel:` + contactNumber"
            >Contact Number: {{ contactNumber }}</a
          >
          <br />
          <q-icon name="email" class="q-mr-sm"></q-icon>
          <a class="text-grey-7" href="mailto:support@carbnbrentals.com"
            >support@carbnbrentals.com</a
          >
        </q-card-section>
      </q-card>
    </div>
  </section>
</template>
<style scoped>
.contact-us {
  max-width: 800px;
  margin: auto;
}
</style>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { notify } from "../utils/notification";
import { useRouter } from "vue-router";

const store = useStore();
const email = ref("");
const firstname = ref();
const lastname = ref();
const phone = ref("");
const message = ref();
const isLoading = ref(false);
const router = useRouter();
const contactNumber = ref(store.state.system.contactNumber);
const generalError = ref("");

function sendInquiry() {
  if (email.value == "" && phone.value == "") {
    generalError.value = "Either an email address or phone number is required.";
    return;
  }
  generalError.value = "";
  isLoading.value = true;

  const payload = {
    name: firstname.value + " " + lastname.value,
    email: email.value,
    mobile_number: phone.value,
    comment: message.value,
  };

  store.dispatch("sendInquiry", payload).then((r) => {
    if (r.status == "success") {
      isLoading.value = false;
      email.value = "";
      firstname.value = "";
      lastname.value = "";
      phone.value = "";
      message.value = "";
      notify(
        "positive",
        "Your inquiry has been sent. We will reach out to you soon.",
        "",
        3000
      );
      setTimeout(() => {
        router.push("/");
      }, 3000);
    }
  });
}
</script>
