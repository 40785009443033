<template>
    <q-dialog v-model="showSignInModal">
        <q-card style="width: 400px;">
            <q-card-section class="q-pt-none">
                <div class="row items-center q-py-md">
                    <div class="text-h6">Sign In</div>
                    <q-space />
                    <q-btn icon="close"
                        flat
                        round
                        dense
                        @click="$emit('close')"
                        :disable="isSigningIn"
                    />
                </div>
                <div>
                    <q-input
                        v-model="email.value"
                        filled
                        type="email"
                        placeholder="Email"
                        class="q-my-md"
                        lazy-rules
                        :rules="[(val) => (val && val.length > 0) || 'Please enter your email address.']"
                        :loading="isSigningIn"
                        :error="email.error != null"
                        :error-message="email.error"
                    />
                    <q-input
                        v-model="password.value"
                        filled
                        :type="!password.show ? 'password' : 'text'"
                        placeholder="Password"
                        label-color="white"
                        class="q-my-md"
                        :loading="isSigningIn"
                        :error="password.error != null"
                        :error-message="password.error"
                    >
                        <template v-slot:append>
                        <q-icon
                            :name="password.show ? 'visibility_off' : 'visibility'"
                            class="cursor-pointer text-white"
                            @click="password.show = !password.show"
                            color="grey"
                        />
                        </template>
                    </q-input>
                    <q-banner inline-actions class="text-white bg-red" v-if="generalError != null">
                        {{ generalError }}
                        <template v-slot:action>
                            <q-btn flat color="white" label="OK" @click="generalError = null" />
                        </template>
                    </q-banner>
                    <div class="text-center q-my-md">
                        <q-btn class="btn-primary" label="Sign In" color="primary" :loading="isSigningIn" @click="signIn()" icon="login" />
                        <q-btn class="btn-primary" label="Register" color="secondary" :disabled="isSigningIn" icon="app_registration" @click="redirectToRegistration"/>
                        <div class="q-mt-md">
                            <q-btn flat label="Forgot Password" :disable="isSigningIn" to="/forgot-password" @click="$emit('close')"/>
                            <q-btn flat label="Verify Email" :disable="isSigningIn" to="/verification"  @click="$emit('close')"/>
                        </div>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { notify } from '../utils/notification';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false
  }
});

const emit = defineEmits(["close"]);
const store = useStore();
const router = useRouter();
const showSignInModal = computed(() => props.show);
const isSigningIn = ref(false);
const name = computed(() => store.getters['user/getFullName']);

const email = ref({
    value: null,
    error: null
});

const password = ref({
    value: null,
    error: null,
    show: false
});

const generalError = ref(null);
function signIn() {
    isSigningIn.value = true;

    email.value.error = null;
    password.value.error = null;

    const payload = {
        email: email.value.value,
        password: password.value.value,
        mode: 'sign-in'
    };

    if (store.state.affiliate.code !== null) {
        payload.code = store.state.affiliate.code;
    }

    store.dispatch('user/signIn', payload).then((response) => {
        switch (response.status) {
            case 'unverified_email':
                router.push("/registration?email=" + email.value.value);
                isSigningIn.value = false;
                emit('close');
                break;
            case 'has_temp_pass':
                notify('warning', 'You entered an incorrect password. This account is using a temporary password from Carbnb. Please check your email inbox for the temporary password.');
                break;
            default:
                email.value.value = null;
                password.value.value = null;

                if (response.affiliate != null) {
                    store.commit('affiliate/setAffiliate', response.affiliate);
                }

                notify('secondary', 'Welcome ' + name.value + '!');
                isSigningIn.value = false;
                emit('close');
                break;
        }
    }).catch((err) => {
        isSigningIn.value = false;
        if (err.response) {
            if (err.response.data.status == 'validation_errors') {
                const errors = err.response.data.data.errors;
                errors.forEach((error) => {
                    if (error.key == 'email') {
                        email.value.error = error.message;
                    }
                    if (error.key == 'password') {
                        password.value.error = error.message;
                    }
                });
            } else {
                if (err.response.status == 404) {
                    generalError.value = 'There is no account found with that email address.';
                    return;
                }

                const error = err.response.data.data.message;
                generalError.value = error;
            }
        } else if (err.request) {
            generalError.value = 'No response from the server. Please try again.'
        } else {
            console.error(err);
            generalError.value = 'An error has occurred. Please try again later.'
        }
    }).finally(() => {
        isSigningIn.value = false;
        password.value.value = null;
    })
}

function redirectToRegistration() {
    showSignInModal.value = false;
    emit('close');
    router.push({
        name: 'Registration'
    });
}
</script>
<style scoped>
.tab-panels {
    min-height: 500px;
}
</style>