<template>
    <section>
      <div :class="padding">
        <q-btn-group spread :style="styleSet">
          <q-btn-dropdown
            v-model="menu"
          >
            <template v-slot:label>
              <div class="btn-search-label">
                <span class="btn-search-title">{{ yard.name }}</span><br>
                <span class="btn-search-selection">Pick Up Location</span>
              </div>
            </template>
            <q-list>
              <q-item clickable v-close-popup @click="onYardSelect(yard.id)" v-for="yard in yards" v-bind:key="yard.id">
                <q-item-section avatar>
                  <q-icon name="location_on" size="md" />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-subtitle1 text-accent">{{ yard.name }}</q-item-label>
                  <q-item-label caption>{{ yard.address }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-separator vertical inset class="gt-md"/>
          <q-separator />
          <q-btn-dropdown
            v-model="menu"
          >
            <template v-slot:label>
              <div class="btn-search-label">
                <span class="btn-search-title">{{ vehicle.name }}</span><br>
                <span class="btn-search-selection">Select Vehicle Type</span>
              </div>
            </template>
            <q-list>
              <q-item clickable v-close-popup @click="onVehicleSelect(vehicle.id)" v-for="vehicle in vehicles" v-bind:key="vehicle.id">
                <q-item-section avatar>
                  <q-img :src="vehicle.image" size="md" />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-subtitle1 text-accent">{{ vehicle.name }}</q-item-label>
                  <q-item-label caption>{{ vehicle.subtitle }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-separator vertical inset class="gt-md" />
          <q-separator />
          <q-btn @click="showDateSetterComponent = !showDateSetterComponent">
            <div class="btn-search-label">
                <span class="btn-search-title">{{ pickupDateLabel }}</span><br>
                <span class="btn-search-selection">Set Pick Up Date</span><br>
            </div>
          </q-btn>
          <q-separator vertical inset class="gt-md" />
          <q-separator />
          <q-btn @click="showDurationSetterComponent = !showDurationSetterComponent">
            <div class="btn-search-label">
                <span class="btn-search-title">{{ returnDateLabel }}</span><br>
                <span class="btn-search-selection">Set Return Date</span>
            </div>
          </q-btn>
          <q-separator vertical inset class="gt-md" />
          <q-separator />
          <q-btn :class="searchBtnClass" color="light" size="lg" rounded flat icon-right="search"
            label="Search" @click="search"></q-btn>
        </q-btn-group>
      </div>
      <q-dialog v-model="showDateSetterComponent" :maximized="$q.screen.lt.md">
        <q-card>
          <q-card-section>
            <DateSetterComponent/>
          </q-card-section>
          <q-card-footer vertical>
            <div class="q-pa-sm">
              <q-btn class="btn-primary" v-close-popup>Confirm</q-btn>
            </div>
          </q-card-footer>
        </q-card>
      </q-dialog>
      <q-dialog v-model="showDurationSetterComponent">
        <q-card>
          <q-card-section>
            <DurationSetterComponent/>
          </q-card-section>
          <q-card-footer vertical>
            <div class="q-pa-sm">
              <q-btn class="btn-primary" v-close-popup @click="search">Confirm</q-btn>
            </div>
          </q-card-footer>
        </q-card>
      </q-dialog>
    </section>
  </template>
  
  <script setup>
  import { onMounted, ref, computed, defineProps, defineEmits } from 'vue';
  import { useQuasar } from 'quasar';
  import { useStore } from "vuex";
  import { useRouter } from 'vue-router';

  import DateSetterComponent from './SearchEngineComponents/DateSetterComponent.vue';
  import DurationSetterComponent from './SearchEngineComponents/DurationSetterComponent.vue';

  const $q = useQuasar();
  const props = defineProps({
    sidebar: {
      type: Boolean,
      required: true,
      default: false
    },
    searchOnClick: {
      type: Boolean,
      required: true,
      default: false
    },
  });

  const store = useStore();
  const router = useRouter();
  const emit = defineEmits(['searchButtonClick']);
  const styleSet = ref('');
  const padding = ref('q-pa-sm');
  const searchBtnClass = ref('btn-search-title text-white btn-primary');
  const showDateSetterComponent = ref(false);
  const showDurationSetterComponent = ref(false);

  const yard = computed(() => store.state.search.yard);
  const yards = computed(() => store.state.search.references.yards);
  const vehicle = computed(() => store.state.search.vehicle);
  const vehicles = computed(() => store.state.search.references.vehicles);
  
  const pickupDateLabel = computed(() => store.state.search.pickupDate.label);
  const returnDateLabel = computed(() => store.state.search.returnDate.label);
  
  const updateButtonGroupOrientation = () => {
    if ($q.screen.lt.md || props.sidebar) {
      styleSet.value = 'flex-direction: column';
    } else {
      styleSet.value = '';
    }
  };

  onMounted(() => {
   updateButtonGroupOrientation();

   if (props.sidebar) {
    padding.value = '';
    searchBtnClass.value = 'btn-search-title text-white btn-primary';
   }
  });
  
  function onYardSelect(id) {
    store.commit('search/setYard', id);
  }
  
  function onVehicleSelect(id) {
    store.commit('search/setVehicle', id);
  }

  function search() {
    if (props.sidebar) {
      store.dispatch('search/getAvailableVehicles', true);
      emit('searchButtonClick', true);
      return;
    }

    if (props.searchOnClick) {
      store.dispatch('search/getAvailableVehicles', true);
      return;
    }

    router.push('/search');
  }
  </script>
  
  <style scoped>
  .btn-search-title {
    text-transform: none;
    font-size: 15px;
    color: black
  }
  .btn-search-selection {
    text-transform: none;
    font-size: 13px;
    color: gray;
    white-space: normal;
    overflow: visible;
    word-break: break-word;
  }
  .btn-search-label {
    line-height: 1.3rem;
    justify-content: flex-start;
    padding: 10px
  }
  .q-menu {
    max-height: 100vh !important;
  }
  </style>