import { createStore } from "vuex";
import axios from "axios";
import booking from "@/store/booking.js";
import user from "@/store/user.js";
import operator from "@/store/operator.js";
import vehicle from "@/store/vehicle.js";
import qr from "@/store/qr.js";
import payment from "@/store/payment.js";
import invoice from "@/store/invoice.js";
import system from "@/store/system.js";
import search from "./search";
import posts from '@/store/posts.js';
import affiliate from "./affiliate";

export default createStore({
  state: {
    isLoggedIn: false,
    openLoginForm: false,
    showAffiliateForm: false,
    displayFooter: true,
    leftDrawerOpen: false,
    email: null
  },

  getters: {},

  actions: {
    verify(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/verify`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
  
    resendVerification(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/resendverification`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },

    forgotPassword(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/users/forgotpassword",
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
          if (response.status == 403) {
            return resolve({
              status: "email_unavailable",
            });
          }
        });
      });
    },

    confirmForgotNewPassword(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/users/forgotpassword/confirm",
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
          if (response.status == 403) {
            return resolve({
              status: "email_unavailable",
            });
          }
        });
      });
    },
    sendInquiry(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/inquiries",
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
          if (response.status == 403) {
            return resolve({
              status: "email_unavailable",
            });
          }
        });
      });
    },
    validateFeedbackForm(_, urlCode) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/feedbacks/validate/${urlCode}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              const feedbacks = response.data.data;

              return resolve({
                status: "success",
                data: feedbacks
              });
            } else {
              return resolve({
                status: response.data.status
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    sendFeedbackForm(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/feedbacks",
          method: "PUT",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    getPullOutData(_, vehicleId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/pullouts/${vehicleId}`,
          method: "GET",
        }).then((response) => {
            return resolve(response);
        }).catch((err) => {
          return reject(err);
        });
      });
    },
    cancelPullOutData(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/pullouts/cancel",
          method: "PUT",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        });
      });
    },
    sendPullOutApplication(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/pullouts/apply",
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        });
      });
    },
  },

  mutations: {
    setisLoggedIn: (state, status) => {
      state.isLoggedIn = status;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setToggleDrawer: (state) => {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    },
    setForVerification: (state, email) => {
      state.email = email;
    },
    toggleAffiliateForm: (state, value) => {
      state.showAffiliateForm = value;
    }
  },
  modules: {
    affiliate: affiliate,
    user: user,
    booking: booking,
    vehicle: vehicle,
    operator: operator,
    qr: qr,
    payment: payment,
    invoice: invoice,
    search: search,
    system: system,
    posts: posts,
  }
});
