import axios from "axios";

const vehicle = {
  state: () => ({
    data: null
  }),

  actions: {
    createVehicle(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/vehicles/add",
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status == "application_submitted") {
              return resolve({
                status: "success",
                id: response.data.data.id
              });
            }
          })
          .catch((err) => {
            return reject(err.response);
          });
      });
    },
    applyAsOperator(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/vehicles/application",
          method: "POST",
          data: payload,
        })
          .then((response) => {
            if (response.data.status) {
              return resolve({
                status: response.data.status,
                id: response.data.data.vehicle_application_id
              });
            }
          })
          .catch((err) => {
            return reject(err.response);
          });
      });
    },
    uploadVehicleGallery(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload.id}/images`,
          method: "POST",
          data: payload.data,
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
                id: response.data.data.vehicle_id
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    updateVehicle(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload.id}`,
          method: "POST",
          data: payload.data,
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    deleteVehicle(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload}`,
          method: "DELETE",
        })
          .then((response) => {
            if (response.data.status == "success") {
              return resolve({
                status: "success",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchSingleVehicle({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload}`,
          method: "GET",
        }).then((response) => {
            if (response.data.status == "vehicle_retrieved") {
              const vehicle = response.data.data;

              commit("setVehicleData", vehicle);
              return resolve({
                status: "success",
                data: vehicle
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    checkAvailability(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/inquire`,
          method: "POST",
          data: payload
        })
          .then((response) => {
            if (response.data.status == 'available') {
              // Save the additional details for the trip details.
              this.commit('invoice/save', response.data.data);
            }

            return resolve({
              status: response.data.status,
              data: response.data.data
            });
          }).catch((err) => {
            return reject(err);
          });
      });
    },
    addtoFavorites() {},
    fetchVehicleReview({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles${payload}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              const vehicle = response.data.data.vehicle;
              commit("setVehicleData", vehicle);
              return resolve({
                status: "success",
                
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchVehicleModels({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/list/vehicle/models`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              commit("setVehicleModels", response.data.data);
              return resolve({
                status: "success",
                models: response.data.data
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    fetchVehicleBlockingDates({ commit },payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/${payload.id}/blockings`,
          method: "GET",
        })
          .then((response) => {
            if (response?.data?.status == "blockings_retrieved") {
              commit("setVehicleBlockingDates", response.data.data);
              return resolve({status: "success"});
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    createBlockingDate(_,payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/blockings`,
          method: "POST",
          data: payload
        })
          .then((response) => {
            if (response?.data?.status == "success") {
              return resolve({
                status: "success",
              });
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject({
              status: err.response.status,
              message: err.response.data.data.message
            });
          });
      });
    },
    deleteBlockingDate(_,payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/blockings/${payload}`,
          method: "DELETE",
        })
          .then((response) => {
            if (response?.data?.status == "success") {
              return resolve({
                status: "success",
              });
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    withdrawApplication(_, vehicleApplicationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/vehicles/application/cancel/${vehicleApplicationId}`,
          method: "DELETE",
        })
          .then((response) => {
            if (response?.data?.status == "success") {
              return resolve({
                status: "success",
              });
            } else {
              return resolve({
                status: "failed",
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    }
  },

  mutations: {
    setVehicleData(state, payload) {
      state.data = payload;
    },
    logout(state) {
      state.data = null;
    }
  },
  namespaced: true,
};

export default vehicle;
