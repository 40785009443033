<template>
  <section class="flex justify-center q-pa-sm">
    <q-card flat bordered style="min-width: 350px; width: 550px; max-width: 600px" class="q-my-lg" v-if="!hasChanged">
        <q-card-section>
            <div class="text-h6">Change Password</div>
            <q-banner inline-actions rounded class="bg-orange text-white q-mt-md" v-if="!hasPersonalizedPassword">
            You are using a temporary password from Carbnb. Please change it to a personalized one.
            </q-banner>
        </q-card-section>
        <q-card-section>
            <q-input
                v-model="currentPassword.value"
                filled
                :type="!currentPassword.show ? 'password' : 'text'"
                autocomplete="off"
                placeholder="Current Password"
                :error="currentPassword.error != null"
                :error-message="currentPassword.error"
                :loading="isLoading"
                class="q-py-md"
                v-if="hasPersonalizedPassword"
            >
                <template v-slot:append>
                    <q-icon
                        :name="currentPassword.show ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer text-white"
                        @click="currentPassword.show = !currentPassword.show"
                        color="grey"
                    />
                </template>
            </q-input>
            <q-input
                v-model="newPassword.value"
                filled
                :type="!newPassword.show ? 'password' : 'text'"
                autocomplete="off"
                placeholder="New Password"
                :error="newPassword.error != null"
                :error-message="newPassword.error"
                :loading="isLoading"
                class="q-py-md"
            >
                <template v-slot:append>
                    <q-icon
                        :name="newPassword.show ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer text-white"
                        @click="newPassword.show = !newPassword.show"
                        color="grey"
                    />
                </template>
            </q-input>
            <q-input
                v-model="confirmPassword.value"
                filled
                :type="!confirmPassword.show ? 'password' : 'text'"
                autocomplete="off"
                placeholder="Repeat New Password"
                :error="confirmPassword.error != null"
                :error-message="confirmPassword.error"
                :loading="isLoading"
                class="q-py-md"
            >
                <template v-slot:append>
                    <q-icon
                        :name="confirmPassword.show ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer text-white"
                        @click="confirmPassword.show = !confirmPassword.show"
                        color="grey"
                    />
                </template>
            </q-input>
        </q-card-section>
        <q-card-section>
            <q-btn class="btn-primary" @click="changePassword">Save Password</q-btn>
            <q-btn class="btn-primary" color="secondary" to="/bookings">Cancel</q-btn>
        </q-card-section>
    </q-card>
    <div class="column justify-between" v-if="hasChanged">
        <q-separator></q-separator>
        <div class="flex justify-center gap-2">
            <span class="text-h6"><q-icon name="check_circle" size="md" class="text-positive"/> Password change successfully!</span>
            <span class="q-mb-lg">Your password has been successfully changed.</span>
            <q-btn class="btn-primary" to="/dashboard">Confirm</q-btn>
        </div>
        <q-separator></q-separator>
    </div>
  </section>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const store = useStore();
const isLoading = ref(false);
const hasChanged = ref(false);

const currentPassword = ref({
    value: null,
    error: null,
    show: false
});

const newPassword = ref({
    value: null,
    error: null,
    show: false
});

const confirmPassword = ref({
    value: null,
    error: null,
    show: false
});
const hasPersonalizedPassword = computed(() => store.state.user.data.has_personalized_password);

function changePassword() {
    currentPassword.value.error = null;
    newPassword.value.error = null;
    confirmPassword.value.error = null;
    var hasError = false;
    isLoading.value = true;

    if (newPassword.value.value != confirmPassword.value.value) {
        confirmPassword.value.error = 'The repeat new password does not match the current new password.';
        hasError = true;
    }

    if (hasPersonalizedPassword.value) {
        if (currentPassword.value.value == null || currentPassword.value.value == '') {
            currentPassword.value.error = 'Please enter your current password.';
            hasError = true;
        }
    }

    if (newPassword.value.value == null || newPassword.value.value == '') {
        newPassword.value.error = 'Please enter your new password.';
        hasError = true;
    }

    if (confirmPassword.value.value == null || confirmPassword.value.value == '') {
        confirmPassword.value.error = 'Please repeat the new password that you entered.';
        hasError = true;
    }

    if (hasError) {
        isLoading.value = false;
        return;
    }

    store.dispatch('user/updatePassword', {
        old_password: currentPassword.value.value,
        password: newPassword.value.value,
    }).then(() => {
        hasChanged.value = true;
    }).catch((err) => {
        if (err.response) {
            if (err.response.status == 400) {
                switch (err.response.data.status) {
                    case 'incorrect_old_password':
                        currentPassword.value.error = 'The current password is incorrect.';
                        break;
                    case 'invalid_password_length':
                        newPassword.value.error = 'Please enter a password with minimum of 8 characters.'
                        break;
                }
            }
        }
    }).finally(() => {
        isLoading.value = false;
    });
}
</script>